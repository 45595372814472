import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import StickersPage from "@/pages/StickersPage.vue";
import CommercialServicesPage from "@/pages/CommercialServicesPage.vue";
import ContactPage from "@/pages/ContactPage.vue";
import DamagedCartsPage from "@/pages/DamagedCartsPage.vue";
import FormPage from "@/pages/FormPage.vue";
import HHWPage from "@/pages/HHWPage.vue";
import ServicesPage from "@/pages/ServicesPage.vue";
import EWastePage from "@/pages/EWastePage.vue";

const routes = [
  { path: "/", name: "Home", component: HomePage },
  { path: "/stickers", name: "Stickers", component: StickersPage },
  { path: "/commercial", name: "Commercial", component: CommercialServicesPage },
  { path: "/contact", name: "Contact", component: ContactPage },
  { path: "/damaged", name: "DamagedCarts", component: DamagedCartsPage },
  { 
    path: "/form",
    component: FormPage,
    children: [
      { 
        path: "", 
        name: "Form", 
        component: FormPage 
      },
      { 
        path: "yard-waste", 
        name: "YardWaste",
        component: FormPage,
        props: { selectedService: 'yard-waste' }
      },
      { 
        path: "sustainable", 
        name: "Sustainable",
        component: FormPage,
        props: { selectedService: 'sustainable' }
      },
      { 
        path: "cart-enrollment", 
        name: "CartEnrollment",
        component: FormPage,
        props: { selectedService: 'cart-enrollment' }
      }
    ]
  },
  { path: "/service", name: "Services", component: ServicesPage },
  { path: "/ewaste", name: "EWaste", component: EWastePage },
  { path: "/hhw", name: "HHW", component: HHWPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;