<template>
    <div>
      <!-- Main content -->
      <div class="language-selector-floating">
        <button class="translate-bubble" @click="toggleTranslator">
          <span class="translate-icon"><div class="icon"><img src="@\assets\ES.png" alt="Language Translation" class="elgin-image" style="width: auto; height: 40px ;"></div></span>
        </button>
        <div :class="['translator-wrapper', { 'is-visible': isTranslatorVisible }]">
          <div id="google_translate_element"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LanguageSelector',
    data() {
      return {
        isTranslatorVisible: false
      }
    },
    mounted() {
      this.initGoogleTranslate();
    },
    methods: {
      initGoogleTranslate() {
        const script = document.createElement('script');
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        document.body.appendChild(script);
  
        window.googleTranslateElementInit = () => {
          new window.google.translate.TranslateElement(
            {
              pageLanguage: 'en',
              layout: window.google.translate.TranslateElement.FloatPosition,
              autoDisplay: false,
            },
            'google_translate_element'
          );
        };
      },
      toggleTranslator() {
        this.isTranslatorVisible = !this.isTranslatorVisible;
      }
    }
  };
  </script>
  
  <style>
  .language-selector-floating {
    position: fixed;
    bottom: 90px; /* Positioned above the menu button */
    right: 20px;
    z-index: 9998;
  }
  
  .translate-bubble {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--lrs-blue);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
  }
  
  .translate-bubble:hover {
    transform: scale(1.1);
  }
  
  .translate-icon {
    font-size: 24px;
    color: white;
  }
  
  .translator-wrapper {
    position: absolute;
    bottom: 70px;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.3s ease;
  }
  
  .translator-wrapper.is-visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  #google_translate_element {
    padding: 12px;
  }
  
  /* Style the Google Translate dropdown */
  #google_translate_element .goog-te-gadget-simple {
    border: none;
    background-color: transparent;
    padding: 8px;
  }
  
  .goog-te-menu-value {
    color: var(--lrs-blue);
    font-family: 'Poppins', sans-serif;
  }
  
  /* Adjust position for mobile */
  @media (max-width: 768px) {
    .language-selector-floating {
      bottom: 90px; /* Adjust based on your floating menu height */
    }
  }
  
  /* Hide Google Translate attribution */
  .goog-te-gadget {
    font-size: 0 !important;
  }
  
  .goog-te-gadget .goog-te-combo {
    margin: 0 !important;
  }
  
  /* Hide the Google Translate banner */
  .goog-te-banner-frame {
    display: none !important;
  }
  
  body {
    top: 0 !important;
  }
  </style>
  