<template>
  <div class="elgin-resident-portal">



<!-- Section 1 -->
<section class="responsive-section">
  <div class="column column-1">
    <!-- Column 1 Content -->
<br>
        
        <h1 class="h1">General Services</h1>



 <br>
  <div class="accordion-container">

    <div class="accordion">
      <div class="accordion-item">
        <div class="accordion-header">
          <div class="icon"><img src="@\assets\LandfillSm.png" alt="Garbage" class="elgin-image" style="width: auto; height: auto;"></div>
          <span><h3 class="h3">GARBAGE SERVICE</h3></span>
          <div class="toggle-icon">+</div>
        </div>
        <div class="accordion-content">
          <p class="paragraph">
  Garbage, recycling, yard waste and organics are collected weekly on the same day. <Strong>Set materials out by 6 AM,</strong> as pickup times may vary. Each home receives a 64-gallon gray garbage cart, with 35 or 96-gallon sizes available upon request. Rates include home collection of Household Hazardous and Electronic Waste.<br><br>           
  Additional bags and resident-owned containers for garbage are accepted with these guidelines: <br>
        </p>
  <ul>
  <li>One refuse sticker required per bag/container</li>
  <li>Must be 35-gallons or less each</li>
  <li>Weight must be under 45 pounds each, when full</li> 
  <li>Containers must have two handles</li>
</ul>
        </div>
      </div>

      <div class="accordion-item">
        <div class="accordion-header">
          <div class="icon"><img src="@\assets\recyicon.png" alt="Recycling" class="elgin-image" style="width: auto; height: auto;"></div>
          <span><h3 class="h3">RECYCLE SERVICE</h3></span>
          <div class="toggle-icon">+</div>
        </div>
        <div class="accordion-content">
          <p class="paragraph">
  Recycling is collected weekly on the same day as garbage, yard waste and organics. LRS collects an unlimited amount of properly prepared recyclable materials. <strong>Please set all materials out by 6 AM.</strong> Each home receives a 64-gallon blue recycling cart.<br>
        </p>
        <img src="@\assets\recycle.png" alt="Recycle Guide" class="elgin-image" style="width: 600px; height: auto;">
       

        </div>
      </div>

      <div class="accordion-item">
        <div class="accordion-header">
          <div class="icon"><img src="@\assets\YardSm.png" alt="Yard Waste" class="elgin-image" style="width: auto; height: auto;"></div>
          <span> <h3 class="h3">YARD WASTE & ORGANICS SERVICE</h3></span>
          <div class="toggle-icon">+</div>
        </div>
        <div class="accordion-content">
          <p class="paragraph">
  Starting January 1, 2025, yard waste and organic stickers will no longer be required. Weekly collection will take place from April 1 through the first full week of December, on your regular collection day.
  </p>
  <h2 class="subheading-1">YARD WASTE GUIDELINES</h2>
  <p class="paragraph">
  Yard waste must be contained in one of the following ways:<br>
  </p>
  <ul>
  <li>In an LRS-provided 35-gallon, 64-gallon or 96-gallon cart</li>
  <li>Biodegradable, 2-ply thickness brown landscape waste bag (50 lb. limit)</li>
  <li>In a 35-gallon customer-owned container with a 50 lb. limit</li>
  <li>Customer containers must have 2 handles</li>
  <li>Brush secured into bundles not exceeding 5 ft. in length, 2 ft. in diameter and 45 lbs.</li>
  </ul>
<br>
  <h2 class="subheading-1">ACCEPTED ORGANICS</h2>

  <ul>
  <li><strong>Food:</strong> Baked goods, Food waste, Grains, Produce</li>
  <li><strong>Non-Food Organics:</strong> Clay, Floral items</li>
  <li>Yard Waste</li>
  </ul>
  <br>
  <h2 class="subheading-1">UNACCEPTED MATERIALS</h2>

  <ul>
  <li><strong>Organic Waste:</strong> Animal waste, Dairy, Meat and bones</li>
  <li><strong>Paper Products:</strong> Bath tissues, Coffee filters, Newspapers, Paper, Paper bags, Biodegradable paper products</li>
  <li><strong>Plastic Items:</strong> Biodegradable plastic products, Plastic bags, Plastic containers, Styrofoam containers</li>
  <li><strong>Construction & Yard Waste:</strong> Bricks, Cardboard, Concrete, Rocks, Wood</li>
  <li><strong>Hazardous Materials:</strong> Grease, Liquids, Oil</li>
  <li><strong>Other:</strong> Diapers, Glass, Wipes, Metal</li>
  </ul>
  <br>
  <h2 class="subheading-1">HOLIDAY TREE REMOVAL</h2>
  <p class="paragraph">
  Live tree collection runs December 26 through January 17. Trees should be bare (no tinsel, lights or ornaments). Trees over 6 feet need to be cut down.
        </p>
        </div>
      </div>



    </div>
  </div>
<br>
  <h1 class="h1">Additional Services</h1>
        <p class="paragraph">
          Here at LRS we provide a variety of services to help your household get rid of items no longer needed.
        </p><br>

        <div class="accordion-container">

<div class="accordion">
  <div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\34.png" alt="Appliance Pick-ups" class="elgin-image" style="width: auto; height: auto;"></div>
      <span><h3 class="h3">APPLIANCE COLLECTION</h3></span>
      <div class="toggle-icon">+</div>
    </div>
    <div class="accordion-content">
      <p class="paragraph">
  Appliances require one $30 appliance sticker per item, available at City Hall. These stickers are different from trash stickers.
        </p>
  <h2 class="subheading-1">ACCEPTED APPLIANCES</h2>

  <ul>
  <li>Air Conditioners</li>
  <li>Dishwashers</li>
  <li>Dryers</li>
  <li>Ovens</li>
  <li>Refrigerators</li>
  <li>Space Heaters</li>
  <li>Water Heaters</li>
  </ul>
    </div>
  </div>

  <div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\35.png" alt="Bulk Items" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3 class="h3">BULK ITEMS</h3></span>
      <div class="toggle-icon">+</div>
    </div>
    <div class="accordion-content">
      <p class="paragraph">
  Bulk items are those that don’t fit in the Elgin garbage cart.
  </p>
  <h2 class="subheading-1">GUIDELINES INCLUDE</h2>

  <ul>
  <li>One Elgin Trash Sticker per item</li>
  <li>Limit of two bulk items per week</li>
  <li>45-pound weight limit per item</li>
  </ul>
<br>
  <h2 class="subheading-1">SPECIFICS</h2>

  <ul>
  <li><strong>Carpet:</strong> One sticker per roll, max 5 ft. long, 2 ft. diameter, up to 8 rolls collected per week under 45 lbs.</li>
  <li><strong>Mattress & Box Spring:</strong> Must be in a taped plastic cover</li>
  <li>Large items may need to be broken down to meet these requirements.</li>
  </ul>

    </div>
  </div>

  <div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\Roll-Off.png" alt="Dumpsters" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3 class="h3">DUMPSTER RENTALS</h3></span>
      <div class="toggle-icon">+</div>
    </div>
    <div class="accordion-content">
      <p class="paragraph">
        Strong. Sturdy. Reliable. LRS dumpsters are essential for any task involving a lot of debris.
      </p><br>
      <button class="lrs-button" style="width: 275px;" aria-label="Learn More"
          onclick="window.open('https://www.lrsrecycles.com/lrs-dumpster-rentals/', '_blank', 'noopener,noreferrer')">
          Learn More
        </button><br><br>
        <button class="lrs-button" style="width: 275px;" aria-label="Order a Dumpster Today"
          onclick="window.open('https://www.lrsrecycles.com/book-roll-off-dumpsters/', '_blank', 'noopener,noreferrer')">
          Order a Dumpster Today
        </button>
    </div>
  </div>
  
  <div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\36.png" alt="HHW" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3 class="h3">HOUSEHOLD HAZARDOUS WASTE (HHW)<br> & ELECTRONIC WASTE COLLECTION</h3></span>
      <div class="toggle-icon">+</div>
    </div>
    <div class="accordion-content">
      <p class="paragraph">
  Use the link below to schedule a collection for any of the items listed below.  
        </p>
  <h2 class="subheading-1">ACCEPTED Household Hazardous Waste</h2>
<p class="paragraph">
  Pick-ups are limited to 70 pounds per collection.
</p><br>
  <ul>
  <li><strong>Automotive:</strong> Antifreeze, batteries, brake fluids, fuels, motor oils, used filters, WD-40</li>
  <li><strong>Batteries:</strong> Lead acid, lithium, Nicad, sump pump</li>
  <li><strong>Household items:</strong> Air Fresheners, Ammonia, hairspray, oven cleaner, shower cleaner, upholstery cleaner</li>
  <li><strong>Medical Disposables:</strong> Needles & Lancets, Sharps</li>
  <li><strong>Mercury items:</strong> Fluorescent bulbs</li>
  <li><strong>Outdoor items:</strong> Driveway sealer, fertilizers, herbicides, insect sprays, pesticides, pool chemicals, weed killers</li>
  <li><strong>Paint Products:</strong> Chromium-base paint, glue, latex, lead, oil, spray paint, stripper, thinner, urethane, wood preservatives</li>
  </ul>
<br>
<button
  class="lrs-button"
  style="width: 275px;"
  aria-label="Schedule Household Hazardous Waste Pickup"
  onclick="window.open('https://lrsrecycles.jotform.com/233125184313144', '_blank', 'noopener,noreferrer')"
>
  Schedule Household Hazardous Waste Pickup
</button>
<br><br>
  <h2 class="subheading-1">ACCEPTED ELECTRONICS</h2>
  <p class="paragraph">
  Pick-ups are limited to a total of 7 items with up to two TV's and/or computer monitors with each pickup.
</p><br>
  <ul>
  <li>Cable Receivers</li>
  <li>Cellular Phones</li>
  <li>Computers (desktop, laptop, notebook, tablet)</li>
  <li>Digital Converter Boxes</li>
  <li>DVD Players</li>
  <li>DVD Recorders</li>
  <li>Electronic Keyboards</li>
  <li>Electronic mouse</li>
  <li>Fax machine</li>
  <li>Music Players</li>
  <li>Printers</li>
  <li>Satellite Receivers</li>
  <li>Scanners</li>
  <li>Small Scale Servers</li>
  <li>TV’s and Monitors (Limit 2)</li>
  <li>VCR Recorders</li>
  <li>Video Game Consoles</li>
  </ul>
<br>
<button
  class="lrs-button"
  style="width: 275px;"
  aria-label="Schedule Electronic Waste Pickup"
  onclick="window.open('https://lrsrecycles.jotform.com/233185896666171', '_blank', 'noopener,noreferrer')"
>
  Schedule Electronic<br>Waste Pickup
</button><br>

    </div>
  </div>
  
  <div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\Portables.png" alt="" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3 class="h3">PORTABLE RESTROOMS</h3></span>
      <div class="toggle-icon">+</div>
    </div>
    <div class="accordion-content">
      <p class="paragraph">
        Whether it’s a construction site, music festival, or home renovation project, good hygiene is a must. Choose LRS for your portable restrooms, sinks, and more to experience superior quality and first-class service at every step. 
        </p>
        <button class="lrs-button" style="width: 275px;" aria-label="Learn More"
          onclick="window.open('https://www.lrsrecycles.com/portable-restroom-solutions/', '_blank', 'noopener,noreferrer')">
          Learn More
        </button><br><br>
        <button class="lrs-button" style="width: 275px;" aria-label="Book Portables"
          onclick="window.open('https://www.lrsrecycles.com/book-portables/', '_blank', 'noopener,noreferrer')">
          Book Portables
        </button>
    </div>
  </div>
  
  <div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\special.png" alt="Special Collections" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3 class="h3">SPECIAL COLLECTIONS</h3></span>
      <div class="toggle-icon">+</div>
    </div>
    <div class="accordion-content">
        <p class="paragraph">
  For move-outs, remodels or disposal of large quantities of bulk or oversized items (such as pianos, basketball hoops, treadmills and tires), special equipment and handling may be required. Please reach out to LRS for assistance by emailing <strong><a href="mailto:elginservice@lrsrecycles.com">ElginService@LRSrecycles.com</a></strong> or calling <strong><a href="tel:855-612-7938">855.612.7938</a></strong>.
        </p>

    </div>
  </div>
</div>
        </div>







      </div>

      <div class="column column-2">
        <!-- Column 2 Content -->
        <div class="contact-image">
      <img src="@\assets\CurattoFLTruck_Resi_3.jpg" alt="LRS Truch" class="responsive-image">
    </div>
<br>
        <h3b class="h3b">REQUEST SERVICES</h3b>
    <br><br>
        <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form';">Organics</button><br><br>
          <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form';">Reduced Waste <br>35-Gallon Waste Cart</button><br><br>
            <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form';">Extra LRS Services</button><br><br>
        <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/damaged';">Replace Damaged<br>or Broken Carts</button><br><br>
          <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/ewaste';">Schedule Electronic<br>Waste Pickup</button><br><br>
          <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/hhw';">Schedule Household<br>Hazardous Waste Pickup</button><br><br>


 </div>
 </section>

     <!-- Section 2 -->
     <section class="section-2">
      <hr class="horizontal-line">
    </section>

    <!-- Section 3 -->
    <section class="section-3">

      <div class="column column-3">
        <h3a class="h3a"><strong>City of Elgin</strong></h3a><br>
        <button class="lrs-button" style="width: 275px;">By Phone at <br><a
            href="tel:847-931-6001">ELGIN 311 / 847.931.6001</a></button><br><br>
        <button class="lrs-button" style="width: 275px;">By Email at <a
          href="mailto:elgin311@cityofelgin.org">elgin311@cityofelgin.com</a></button>


      </div>

      <div class="column column-4">

        <h3a class="h3a"><strong>LRS</strong></h3a><br>
        <button class="lrs-button" style="width: 275px;">By Phone at<br> <a
            href="tel:855-612-7938">855.612.7938</a></button><br><br>
        <button class="lrs-button" style="width: 275px;">By Email at <a
            href="mailto:elginservice@lrsrecycles.com">ElginService@LRSrecycles.com</a></button>

       </div>

      <div class="column column-5">
        <h3a class="h3a">Download Brochure</h3a><br>
        <img src="@\assets\brochure.png" alt="Elgin Brochure" class="image">
      </div>
    </section>
</div>
</template>

<script>
export default {
  name: 'ServicesPage',
  mounted() {
    // Add event listeners for the accordion when the component is mounted
    document.querySelectorAll('.accordion-header').forEach((header) => {
      header.addEventListener('click', () => {
        const item = header.parentElement;
        const content = item.querySelector('.accordion-content');

        // Toggle visibility
        if (content.style.display === 'block') {
          content.style.display = 'none';
          header.querySelector('.toggle-icon').textContent = '+';
        } else {
          content.style.display = 'block';
          header.querySelector('.toggle-icon').textContent = '-';
        }
      });
    });
  },
};
</script>


<style scoped>


/* Section Layouts for Different Sizes */
.section-1,
.section-3 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Columns Configuration */
.column {
  box-sizing: border-box;
  padding: 20px;
  flex: 1 1 auto;
  min-width: 200px;
  /* Ensure columns do not shrink too much */
}

.column-1 {
  flex: 1 1 60%;
}

.column-2 {
  flex: 1 1 35%;

}

/* Responsive Column Adjustments */
@media (max-width: 768px) {

.column-1,
.column-2,
.column-3,
.column-4,
.column-5 {
  flex: 1 1 100%;
  text-align: left;
  /* Adjust text alignment for mobile */
}
.h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 28px;
  color: var(--lrs-blue);
}

.h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: var(--recycle-green);
  margin-top: 10px;
  margin-bottom: 10px;
}
.subheading-1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--safety-orange);
}
.h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--recycle-green);
}
}

/* Media Queries for Smaller Devices */
@media (max-width: 768px) {

  .section-1,
  .section-3 {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
  }

  .card {
    width: 90%;
    margin: 10px 0;
  }

  .elgin-resident-portal {
    padding: 10px;
  }
}

/* Section Divider */
.section-2 {
  margin: 20px 0;
}

.horizontal-line {
  height: 5px;
  background-color: var(--lrs-blue);
  border: none;
}
 .accordion {
    margin-top: 0px;
    position: left; /* Allows placement relative to the container */
    vertical-align: top;
    width: 100%;
    max-width: 600px;

  }
  
  .accordion-item {
    border-bottom: 1px solid #eee;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    cursor: pointer;
    padding: 10px 0;
  }
  
  .accordion-header .icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .accordion-header span {
    flex: 1;
    font-weight: bold;
    color: #333;
  }
  
  .accordion-header .toggle-icon {
    font-size: 18px;
    color: #153683;
  }
  
  .accordion-content {
    display: none;
    padding: 10px 0;
    color: black;
    font-size: 16px;
  }
</style>