<!-- ErrorStatus.vue -->
<template>
    <div v-if="show" class="error-status">
      <div class="status-content">
        <div class="error-icon">!</div>
        <h2>Request Processing Error</h2>
        <p>We were unable to process your request at this time.</p>
        <div class="support-info">
          <p>Please contact LRS support for assistance:</p>
          <div class="contact-methods">
            <a href="tel:855-612-7938" class="contact-link">
              <span class="icon">📞</span>
              855.612.7938
            </a>
            <a href="mailto:elginservice@lrsrecycles.com" class="contact-link">
              <span class="icon">✉️</span>
              ElginService@LRSrecycles.com
            </a>
          </div>
        </div>
        <div class="button-group">
          <button @click="$emit('retry')" class="retry-button">
            Try Again
          </button>
          <button @click="$emit('close')" class="close-button">
            Close
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ErrorStatus',
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    emits: ['close', 'retry']
  }
  </script>
  
  <style scoped>
  .error-status {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .status-content {
    text-align: center;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    width: 90%;
  }
  
  .error-icon {
    width: 60px;
    height: 60px;
    background: #e74c3c;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 auto 1rem;
  }
  
  h2 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  .support-info {
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 6px;
    margin: 1rem 0;
  }
  
  .contact-methods {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .contact-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: #2980b9;
    text-decoration: none;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .contact-link:hover {
    background-color: #edf2f7;
  }
  
  .icon {
    font-size: 1.2rem;
  }
  
  .button-group {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1.5rem;
  }
  
  .retry-button, .close-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.2s;
    border: none;
  }
  
  .retry-button {
    background: #3498db;
    color: white;
  }
  
  .retry-button:hover {
    background: #2980b9;
  }
  
  .close-button {
    background: #e0e0e0;
    color: #333;
  }
  
  .close-button:hover {
    background: #d0d0d0;
  }
  </style>