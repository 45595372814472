<template>
      <br>
      <h2 class="h2">Extra LRS Services Sign-up</h2><br>
  <div class="step-form">
    <h3 class="step-title">Step {{ step }} of 5</h3>

    <keep-alive>
      <component :is="currentStepComponent" :form="form" @update-form="updateForm" />
    </keep-alive>

    <div class="form-actions">
      <div class="container">
        <button v-if="step !== 1" @click="prevStep" class="button secondary">
          Previous
        </button>
        <button v-if="step < 5" @click="nextStep" :disabled="!isStepValid" class="button primary">
          Next
        </button>
        <button v-if="step === 5" @click="submitForm" class="button success" type="button">
          Submit
        </button>
      </div>
    </div>
    <loading-overlay :show="loading" />
    <submission-status :show="showSuccess" @close="handleSuccessClose" />
    <error-status :show="showError" @close="handleErrorClose" @retry="handleRetry" />
  </div>
</template>

<script>
import Step1ContactInformation from '@/components/Steps/Step1ContactInformation.vue';
import Step2AddressInformation from '@/components/Steps/Step2AddressInformation.vue';
import Step3WasteRecycling from '@/components/Steps/Step3WasteRecycling.vue';
import Step4AdditionalCarts from '@/components/Steps/Step4AdditionalCarts.vue';
import Step5ReviewSubmit from '@/components/Steps/Step5ReviewSubmit.vue';
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import SubmissionStatus from "@/components/SubmissionStatus.vue";
import ErrorStatus from '@/components/ErrorStatus.vue';
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
import { NotificationService } from '@/utils/notificationService';

const generateGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const isUpsize = (current, requested) => {
  if (requested === 'No Change Needed') return false;
  const currentSize = parseInt(current);
  const requestedSize = parseInt(requested);
  return requestedSize > currentSize;
};

export default {
  name: 'StepForm',
  components: {
    Step1ContactInformation,
    Step2AddressInformation,
    Step3WasteRecycling,
    Step4AdditionalCarts,
    Step5ReviewSubmit,
    LoadingOverlay,
    SubmissionStatus,
    ErrorStatus
  },
  data() {
    return {
      step: 1,
      loading: false, // Controls the loading overlay
      showSuccess: false,
      showError: false, // Controls the submission success screen

      form: {
        title: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneType: "mobile",
        phoneNumber: "",
        isEmailValid: false,
        serviceupdate: true,
        marketing: true,
        contactMethod: [],
        address: "",
        NoAddress: "",
        address1: "",
        address2: "",
        city: "ELGIN",
        state: "IL",
        zip: "",
        county: "",
        siteNumber: null,
        currentWasteCart: "64-Gallon",
        requestedWasteCart: "No Change Needed",
        currentRecycleCart: "64-Gallon",
        requestedRecycleCart: "No Change Needed",
        requestedYardCart: "No Cart Needed",
        additional: "no",
        AddTr64: "0",
        AddTr96: "0",
        AddYr64: "0",
        AddYr96: "0",
        AddRec64: "0",
        AddRec96: "0",
        UpsizeAck: "",
        RenewalAck: "",
        BillingDifferent: "",
        billingattention: "",
        billingaddress1: "",
        billingaddress2: "",
        billingcity: "",
        billingstate: "",
        billingzip: "",
        completedBy: "Resident"
      }
    };
  },
  computed: {
    currentStepComponent() {
      const steps = [
        'Step1ContactInformation',
        'Step2AddressInformation',
        'Step3WasteRecycling',
        'Step4AdditionalCarts',
        'Step5ReviewSubmit'
      ];
      return steps[this.step - 1];
    },
    isStepValid() {
      switch (this.step) {
        case 1:
          return true;
        case 2:
          return Boolean(this.form.address1 && this.form.city && this.form.state && this.form.zip && this.form.siteNumber);
        case 3:
          return this.form.additional === 'no' || this.hasAdditionalCart;
        case 4:
          return true;
        case 5:
          return this.isStep5Valid;
        default:
          return true;
      }
    },
    isStep5Valid() {
      return (
        (!this.showUpsizeAcknowledgement || this.form.UpsizeAck === 'I Accept') &&
        (!this.form.RenewalAck || this.form.RenewalAck === 'I Accept') &&
        (!this.form.BillingDifferent || (
          this.form.billingattention &&
          this.form.billingaddress1 &&
          this.form.billingcity &&
          this.form.billingstate &&
          this.form.billingzip
        ))
      );
    },
    hasAdditionalCart() {
      return (
        parseInt(this.form.AddTr64) > 0 ||
        parseInt(this.form.AddTr96) > 0 ||
        parseInt(this.form.AddRec64) > 0 ||
        parseInt(this.form.AddRec96) > 0 ||
        parseInt(this.form.AddYr64) > 0 ||
        parseInt(this.form.AddYr96) > 0
      );
    },
    showUpsizeAcknowledgement() {
      const wasteCart = (this.form.requestedWasteCart || "").toLowerCase();
      const recycleCart = (this.form.requestedRecycleCart || "").toLowerCase();
      return wasteCart === "96-gallon" || recycleCart === "96-gallon";
    }
  },
  methods: {
    updateForm(updates) {
      this.form = { ...this.form, ...updates };
    },
    nextStep() {
      if (this.step < 5) this.step++;
    },
    prevStep() {
      if (this.step > 1) this.step--;
    },

    generateUpsizePayloads() {
      const payloads = [];

      if (isUpsize(this.form.currentWasteCart, this.form.requestedWasteCart)) {
        payloads.push({
          CM05_CUST: 26898,
          CM05_SITE: this.form.siteNumber,
          CM05_TYPE: "RG",
          CM05_SIZE: 96.0,
          CM05_QTY: 1,
          CM19_ACTV: "TQ",
          CM19_RATE: 33.80,
          FORMTITLE: "Upsize Cart"
        });
      }

      if (isUpsize(this.form.currentRecycleCart, this.form.requestedRecycleCart)) {
        payloads.push({
          CM05_CUST: 26898,
          CM05_SITE: this.form.siteNumber,
          CM05_TYPE: "RR",
          CM05_SIZE: 96.0,
          CM05_QTY: 1,
          CM19_ACTV: "AT",
          CM19_RATE: 33.80,
          FORMTITLE: "Upsize Cart"
        });
      }

      return payloads;
    },

    generateAdditionalCartServices() {
      const additionalServices = [];

      const addCart = (type, size, qty, rate) => {
        const quantity = parseInt(qty);
        if (quantity > 0) {
          additionalServices.push({
            CM05_CUST: 26898,
            CM05_SITE: this.form.siteNumber,
            CM05_TYPE: type,
            CM05_SIZE: size,
            CM05_QTY: quantity,
            CM19_ACTV: "AT",
            CM19_RATE: (rate / 12).toFixed(2)
          });
        }
      };

      addCart("RG", 64, this.form.AddTr64, 191.4);
      addCart("RG", 96, this.form.AddTr96, 225.0);
      addCart("RR", 64, this.form.AddRec64, 191.4);
      addCart("RR", 96, this.form.AddRec96, 225.0);
      addCart("RY", 64, this.form.AddYr64, 191.4);
      addCart("RY", 96, this.form.AddYr96, 225.0);

      return additionalServices;
    },

    generateFinalPayload() {
      const basePayload = {
        SITEGUID: generateGUID(),
        FORMTITLE: "Create Special Service",
        CM04_NAME: `${this.form.firstName} ${this.form.lastName}`.toUpperCase(),
        CM04_FLD3: this.form.county,
        CM04_ADD2: this.form.address1,
        CM04_ADD3: this.form.address2 || "",
        CM04_CITY: this.form.city,
        CM04_STAT: this.form.state,
        CM04_PZIP: this.form.zip,
        CC01_FNAME: this.form.firstName.toUpperCase(),
        CC01_LNAME: this.form.lastName.toUpperCase(),
        CM04_ISMAILSAME: this.form.BillingDifferent !== 'Billing Different',
        CC01_PHONETYPE: this.form.phoneType,
        CC01_PHONE: this.form.phoneNumber,
        CC01_EMAIL: this.form.email,
        CC01_SALU: this.form.title,
        SERVICE_UPDATES_FLAG: this.form.serviceupdate,
        MARKETING_UPDATES_FLAG: this.form.marketing,
        PREFERRED_CONTACT_METHOD: this.form.contactMethod.join(","),
        ROUTING_SITE: this.form.siteNumber,
        ROUTING_CUST: 26898,

      };

      if (this.form.BillingDifferent === 'Billing Different') {
        Object.assign(basePayload, {
          CM04_MNAM: this.form.billingattention.toUpperCase(),
          CM04_MAD1: this.form.billingaddress1,
          CM04_MAD2: this.form.billingaddress2 || "",
          CM04_MCIT: this.form.billingcity,
          CM04_MSTA: this.form.billingstate,
          CM04_MPZI: this.form.billingzip
        });
      }

      // Add waste upsize service to final payload if requested
      const services = [];
      if (isUpsize(this.form.currentWasteCart, this.form.requestedWasteCart)) {
        services.push({
          SERVGUID: generateGUID(),
          CM05_CUST: 26898,
          CM05_SITE: this.form.siteNumber,
          CM05_TYPE: "RG",
          CM05_SIZE: 96.0,
          CM05_QTY: 1,
          CM19_ACTV: "PS",
          CM19_RATE: (33.80 / 12).toFixed(2)
        });
      }

      // Add additional cart services
      const additionalServices = this.generateAdditionalCartServices()
        .map(service => ({
          ...service,
          SERVGUID: generateGUID()
        }));

      basePayload.services = [...services, ...additionalServices];
      return basePayload;
    },
    async submitForm() {
      this.loading = true;
      this.showError = false;

      try {
        const finalPayload = this.generateFinalPayload();

        const finalResponse = await fetch(API_ENDPOINT, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(finalPayload)
        });

        if (!finalResponse.ok) {
          throw new Error("Failed to submit the final payload.");
        }

        const upsizePayloads = this.generateUpsizePayloads();
        if (upsizePayloads.length > 0) {
          const upsizeRequests = upsizePayloads.map((payload) =>
            fetch(API_ENDPOINT, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(payload)
            })
          );

          const upsizeResponses = await Promise.all(upsizeRequests);
          if (upsizeResponses.some((response) => !response.ok)) {
            throw new Error("Failed to submit one or more upsize payloads.");
          }
        }

        // Both payloads succeeded, now send the notification
        await NotificationService.sendEmail(
          this.form.email,
          "LRS Cart Request Confirmation",
          NotificationService.getFormSubmissionTemplate()
        );

        this.loading = false;
        this.showSuccess = true;

      } catch (error) {
        console.error("Error submitting form:", error);
        this.loading = false;
        this.showError = true;
      }
    },

    handleErrorClose() {
      this.showError = false;
    },

    handleRetry() {
      this.showError = false;
      this.submitForm(); // Retry the submission
    },

    handleSuccessClose() {
      this.showSuccess = false;
      // Optionally reset the form or redirect
      this.$router.push('/'); // Redirect to home page, or wherever appropriate
    }
  }

};
</script>

<style src="./StepForm.css"></style>
