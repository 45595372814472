<template>
    <div class="form-group">
      <h2 class="subheading-1">Sustainable Cart Options</h2>
      
      <div class="info-section">
        <p class="paragraph">
          If you do not generate more then two (2) trash bags each week, this option may work for you. Additional trash placed outside of the cart requires Elgin Trash Sticker(s). Please select the Downsize Waste Cart to 35-Gallon below if you'd like to make this change.
        </p>
      </div>
  
      <div class="cart-options">
        <div class="option-card">
          <div class="option-content">
            <label class="checkbox-container">
              <input 
                type="checkbox" 
                :checked="form.selectedOptions.includes('RG')"
                @change="toggleOption('RG')"
              >
              <span class="checkmark"></span>
              <span class="label-text">Downsize Waste Cart to 35-Gallon</span>
            </label>
          </div>
        </div>
  
    
  
        <div class="info-box" v-if="form.selectedOptions.length > 0">
          <i class="fas fa-info-circle"></i>
          <p>
            You've chosen to downsize your {{ getCartTypes }} to a 35-gallon size. 
            This helps reduce waste and supports environmental sustainability.
          </p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  const CART_TYPES = {
    RG: 'waste cart',
  };
  
  export default {
    name: 'Step2SustainableSelection',
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    computed: {
      getCartTypes() {
        const selectedCarts = this.form.selectedOptions.map(type => CART_TYPES[type]);
        return selectedCarts.length > 1 
          ? `${selectedCarts.join(' and ')}s` 
          : selectedCarts[0];
      }
    },
    emits: ['update-form'],
    methods: {
      toggleOption(cartType) {
        const currentOptions = [...this.form.selectedOptions];
        const index = currentOptions.indexOf(cartType);
        
        if (index === -1) {
          currentOptions.push(cartType);
        } else {
          currentOptions.splice(index, 1);
        }
  
        this.$emit('update-form', {
          ...this.form,
          selectedOptions: currentOptions
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .cart-options {
    margin: 2rem 0;
  }
  
  .option-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1rem;
  }
  
/* Checkbox Styling */
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  transform: scale(1.5);
  margin-right: 10px;
  cursor: pointer;
}

/* Container Styling */
.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
}

/* Label Styling */
.checkbox-container label {
  font-size: 16px;
  color: #333;
  margin: 0;
}
  
  .label-text {
    margin-left: 0.5rem;
    font-size: 1.1rem;
  }
  
  .info-box {
    margin-top: 2rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-left: 4px solid var(--primary-color);
    border-radius: 4px;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .info-box i {
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-top: 0.2rem;
  }
  
  .info-box p {
    margin: 0;
    color: var(--text-secondary);
  }
  </style>