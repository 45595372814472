<template>
  <div class="elgin-resident-portal">
    <!-- Section 1 -->
    <section class="responsive-section">
      <br>
        <!-- Column 1 Content -->
<body>

  <div class="container">
    <!-- Left Column -->
     
    <div class="contact-info">
      <h1 class="h1">Contact Us</h1><br><br>
      <h2 class="h2">City of Elgin</h2>
        <button class="lrs-button" style="width: 275px;">By Phone at<br> <a
            href="tel:847-931-6001">ELGIN 311 / 847.931.6001</a></button><br><br>
        <button class="lrs-button" style="width: 275px;">By Email at <a
          href="mailto:elgin311@cityofelgin.org">elgin311@cityofelgin.com</a></button><br><br>
        <h2 class="h2">LRS</h2>
        <button class="lrs-button" style="width: 275px;">By Phone at <a
            href="tel:855-612-7938">855.612.7938</a></button><br><br>
        <button class="lrs-button" style="width: 275px;">By Email at <a
            href="mailto:elginservice@lrsrecycles.com">ElginService@LRSrecycles.com</a></button><br><br>

      </div>

    <!-- Right Column -->
    <div class="contact-image">
      <img src="@\assets\CurattoFLTruck_Resi_3.jpg" alt="LRS Truch" class="responsive-image">
    </div>
  </div>
</body>

    </section>

    <!-- Section 2 -->
    <section class="section-2">
      <hr class="horizontal-line">
    </section>

    <!-- Section 3 -->
    <section class="section-3">
      <div class="column column-3">

        <!DOCTYPE html>
        <html lang="en">

        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Dumpster Card</title>
          <!-- Link to Google Fonts -->
          <!-- Link to CSS -->
          <link rel="stylesheet" href="style.css">
        </head>

        <body>
          <div class="card">

            <h2 class="card-title">Having a Party or Event?</h2>
            <p class="card-description">LRS can help with portable rentals in a variety of options and sizes.</p>
            <a href="#" class="card-link">Explore Services &rarr;</a>
          </div>
        </body>

        </html>
      </div>

      <div class="column column-4">


        <!DOCTYPE html>
        <html lang="en">

        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Dumpster Card</title>
          <!-- Link to Google Fonts -->
         <!-- Link to CSS -->
          <link rel="stylesheet" href="style.css">
        </head>

        <body>
          <div class="card">

            <h2 class="card-title">Need a Dumpster?</h2>
            <p class="card-description">Renovating? Have a big clean up? Let LRS help. We have a variety of dumpsters
              that can be delivered and picked up on your schedule.</p>
            <a href="#" class="card-link">Explore Services &rarr;</a>
          </div>
        </body>

        </html>
      </div>

      <div class="column column-5">

      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style scoped>
.elgin-resident-portal {
  padding: 20px;
}

.section-1 {
  display: flex;
  gap: 20px;
}

.column {
  box-sizing: border-box;
}

.column-1 {
  width: 50%;
}

.column-2 {
  width: 50%;
}

.section-2 {
  margin: 20px 0;
}

.horizontal-line {
  height: 5px;
  background-color: var(--lrs-blue);
  border: none;
}

.section-3 {
  display: flex;
  gap: 20px;
}

.column-3,
.column-4,
.column-5 {
  width: 33%;
  text-align: left;
}

.separator {
  border: none;
  border-top: 1px solid var(--white);
  margin: 10px 0;
}

.elgin-image {
  width: 100%;
  margin-bottom: 20px;
}
/* Responsive Column Adjustments */
@media (max-width: 768px) {

.column-1,
.column-2,
.column-3,
.column-4,
.column-5 {
  flex: 1 1 100%;
  text-align: left;
  /* Adjust text alignment for mobile */
}
.h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 32px;
  color: var(--lrs-blue);
}

.h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: var(--recycle-green);
  margin-top: 10px;
  margin-bottom: 10px;
}
.subheading-1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: var(--safety-orange);
}
.h3a {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--lrs-blue);
}
}
/* Media Queries for Smaller Devices */
@media (max-width: 768px) {

.section-1,
.section-3 {
  flex-direction: column;
  align-items: center;
}

.column {
  width: 100%;
}

.card {
  width: 90%;
  margin: 10px 0;
}

.elgin-resident-portal {
  padding: 10px;
}
}
</style>
