<template>
  <div class="form-group">
    <h2 class="subheading-1">Contact Information</h2>

    <!-- Title -->
    <div class="input-group">
      <label for="title"><strong>Title:</strong></label>
      <select :value="form.title" @change="emitUpdate({ title: $event.target.value })" id="title">
        <option value="Miss">Miss</option>
        <option value="Mrs.">Mrs.</option>
        <option value="Mr.">Mr.</option>
        <option value="Dr.">Dr.</option>
        <option value="Prof.">Prof.</option>
      </select>
    </div>

    <!-- First Name -->
    <div class="input-group">
      <label for="firstName"><strong>First Name:</strong></label>
      <input :value="form.firstName" @input="emitUpdate({ firstName: $event.target.value })" type="text" id="firstName"
        placeholder="Enter your first name" />
    </div>

    <!-- Last Name -->
    <div class="input-group">
      <label for="lastName"><strong>Last Name:</strong></label>
      <input :value="form.lastName" @input="emitUpdate({ lastName: $event.target.value })" type="text" id="lastName"
        placeholder="Enter your last name" />
    </div>

    <!-- Email -->
    <div class="input-group">
      <label for="email"><strong>Email:</strong></label>
      <input :value="form.email" @input="emitUpdate({ email: $event.target.value })" type="email" id="email"
        placeholder="Enter your email" />
    </div>


    <!-- Phone Section -->
    <div class="input-group">
      <label><strong>Phone Number:</strong></label>
      <div class="phone-input-container">
        <select :value="form.phoneType" @change="emitUpdate({ phoneType: $event.target.value })"
          class="phone-type-select">
          <option value="mobile">Mobile</option>
          <option value="landline">Landline</option>
        </select>
        <input :value="formatPhoneNumber(form.phoneNumber)" @input="handlePhoneInput" type="tel"
          placeholder="123-456-7890" class="phone-input" :class="{ 'invalid': !isPhoneValid && form.phoneNumber }" />
      </div>
      <span class="error-message" v-if="!isPhoneValid && form.phoneNumber">
        Please enter a valid 10-digit phone number
      </span>
    </div>

    <!-- Service Updates Toggle -->
    <ToggleSwitch label="Cart Delivery & Service Notifications"
      hint="Receive notifications about cart deliveries, exchanges and service updates."
      :model-value="form.serviceupdate" @update:model-value="(value) => emitUpdate({ serviceupdate: value })" />

    <!-- Marketing Toggle -->
    <ToggleSwitch label="Marketing Communications" hint="Receive other service offers and deals from LRS marketing."
      :model-value="form.marketing" @update:model-value="(value) => emitUpdate({ marketing: value })" />

    <!-- Contact Methods -->
    <ContactMethodSelect label="Preferred Method of Contact" :model-value="form.contactMethod"
      :options="['Phone', 'Text', 'Email']" @update:model-value="(value) => emitUpdate({ contactMethod: value })" />
  </div>
</template>

<script>
import ToggleSwitch from '../ToggleSwitch.vue'
import ContactMethodSelect from '../ContactMethodSelect.vue'

export default {
  name: 'Step1ContactInformation',
  components: {
    ToggleSwitch,
    ContactMethodSelect
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    isPhoneValid() {
      const digits = this.form.phoneNumber?.replace(/\D/g, '') || '';
      return digits.length === 10;
    }
  },
  methods: {
    emitUpdate(updates) {
      this.$emit('update-form', updates);
    },
    formatPhoneNumber(phone) {
      if (!phone) return '';
      const digits = phone.replace(/\D/g, '');
      if (digits.length <= 3) return digits;
      if (digits.length <= 6) return `${digits.slice(0, 3)}-${digits.slice(3)}`;
      return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
    },
    handlePhoneInput(event) {
      const digits = event.target.value.replace(/\D/g, '').slice(0, 10);
      this.emitUpdate({
        phoneNumber: digits // Store only digits
      });
    }
  }
};
</script>

<style scoped>
.phone-input.invalid {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.phone-input-container {
  display: flex;
  gap: 8px;
}

.phone-type-select {
  width: 120px;
}

.phone-input {
  flex: 1;
}
</style>