import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; 
import './main.css'; // Import custom styles
import Clarity from '@microsoft/clarity';

// Make sure to add your actual project id instead of "yourProjectId".
const projectId = process.env.VUE_APP_CLARITY_PROJECT_ID;

Clarity.init(projectId);

createApp(App)
  .use(router) // Register router
  .mount('#app');
