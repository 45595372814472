<template>
    <div class="cart-options">
      <div class="info-section">
        <h2 class="subheading-1">Up-size to 96-Gallon</h2>
        <p class="paragraph">
          Select which cart(s) you would like to upsize to 96-gallon capacity. 
          Larger carts help reduce overflow and accommodate higher volume needs.
        </p>
      </div>
  
      <!-- Waste Cart Option -->
      <div class="option-card">
        <div class="option-header">
          <i class="fas fa-trash-alt icon"></i>
          <h3>Waste Cart</h3>
        </div>
        <div class="option-content">
          <div class="current-size">
            Current Size: 
            <select 
              :value="form.currentWasteCart"
              @change="emitUpdate({ currentWasteCart: $event.target.value })"
              class="size-select"
            >
              <option value="35-Gallon">35-Gallon</option>
              <option value="64-Gallon">64-Gallon</option>
              <option value="Not Sure">Not Sure</option>
            </select>
          </div>
          <div class="upsize-option">
            <label class="toggle-container">
              <input 
                type="checkbox"
                :checked="form.requestedWasteCart === '96-Gallon'"
                @change="handleWasteUpsize"
              >
              <span class="toggle-label">Upsize to 96-Gallon</span>
            </label>
            <span class="price-note">Additional $33.80/year</span>
          </div>
        </div>
      </div>
  
      <!-- Recycling Cart Option -->
      <div class="option-card">
        <div class="option-header">
          <i class="fas fa-recycle icon"></i>
          <h3>Recycling Cart</h3>
        </div>
        <div class="option-content">
          <div class="current-size">
            Current Size: 
            <select 
              :value="form.currentRecycleCart"
              @change="emitUpdate({ currentRecycleCart: $event.target.value })"
              class="size-select"
            >
              <option value="35-Gallon">35-Gallon</option>
              <option value="64-Gallon">64-Gallon</option>
              <option value="Not Sure">Not Sure</option>
            </select>
          </div>
          <div class="upsize-option">
            <label class="toggle-container">
              <input 
                type="checkbox"
                :checked="form.requestedRecycleCart === '96-Gallon'"
                @change="handleRecycleUpsize"
              >
              <span class="toggle-label">Upsize to 96-Gallon</span>
            </label>

          </div>
        </div>
      </div>
  
      <!-- Yard Waste Cart Option -->
      
    </div>
  </template>
  
  <script>
  export default {
    name: 'Step3WasteRecycling',
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    methods: {
      emitUpdate(updates) {
        this.$emit('update-form', { ...this.form, ...updates });
      },
      handleWasteUpsize(event) {
        this.emitUpdate({
          requestedWasteCart: event.target.checked ? '96-Gallon' : 'No Change Needed'
        });
      },
      handleRecycleUpsize(event) {
        this.emitUpdate({
          requestedRecycleCart: event.target.checked ? '96-Gallon' : 'No Change Needed'
        });
      },
      handleYardUpsize(event) {
        this.emitUpdate({
          requestedYardCart: event.target.checked ? '96-Gallon' : 'No Cart Needed'
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .cart-options {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .info-section {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .option-card {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .option-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .icon {
    font-size: 1.5rem;
    color: var(--primary-color);
    margin-right: 1rem;
  }
  
  .option-header h3 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--text-primary);
  }
  
  .option-content {
    padding-left: 2.5rem;
  }
  
  .current-size {
    margin-bottom: 1rem;
  }
  
  .size-select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-left: 0.5rem;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .toggle-container input[type="checkbox"] {
    margin-right: 0.5rem;
    width: 18px;
    height: 18px;
  }
  
  .toggle-label {
    font-weight: 500;
  }
  
  .price-note {
    display: block;
    margin-left: 1.5rem;
    font-size: 0.9rem;
    color: var(--text-secondary);
    margin-top: 0.25rem;
  }
  
  .upsize-option {
    padding: 0.5rem 0;
  }
  </style>