<!-- SubmissionStatus.vue -->
<template>
    <div v-if="show" class="submission-status">
      <div class="status-content">
        <div class="success-icon">✓</div>
        <h2>Submission Successful!</h2>
        <p>Your cart service request has been submitted successfully.</p>
        <p class="confirmation">A confirmation email will be sent to your provided email address.</p>
        <button @click="$emit('close')" class="close-button">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SubmissionStatus',
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    emits: ['close']
  }
  </script>
  
  <style scoped>
  .submission-status {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .status-content {
    text-align: center;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    width: 90%;
  }
  
  .success-icon {
    width: 60px;
    height: 60px;
    background: #4CAF50;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin: 0 auto 1rem;
  }
  
  h2 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  .confirmation {
    color: #666;
    font-size: 0.9rem;
    margin: 1rem 0;
  }
  
  .close-button {
    background: #3498db;
    color: white;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.2s;
  }
  
  .close-button:hover {
    background: #2980b9;
  }
  </style>