<template>
    <div>
      <!-- Initial Additional Cart Section -->
      <div class="form-group">
        <h2 class="subheading-1">Additional Cart Enrollment</h2>
  
        <p class="paragraph">
          LRS offers additional carts in 64 & 96-gallon sizes for waste, recyclables, yard waste and organics to rent.
        </p>
  
        <!-- Toggle switch for additional cart -->
        <div class="toggle-container">
          <label class="toggle-label">
            <span><strong>Additional Cart Request</strong></span>
            <div class="toggle-switch">
              <input 
                type="checkbox" 
                :checked="form.additional === 'yes'"
                @change="emitUpdate({ additional: $event.target.checked ? 'yes' : 'no' })"
              >
              <span class="slider"></span>
            </div>
          </label>
          <small>Select if you'd like to request additional carts</small>
        </div>
      </div>
  
      <!-- Additional Cart Details Section -->
      <div v-if="form.additional === 'yes'" class="form-group">
        <p class="paragraph">
          Additional carts are billed annually in advance. Your first charge will be based on the delivery date.
          Cancellation requests must be received by December 1st to avoid recurring charges.
        </p>
  
        <div class="cart-rates-info">
          <div class="rate-card">
            <span class="rate-size">64-Gallon</span>
            <span class="rate-price">$191.40/year</span>
          </div>
          <div class="rate-card">
            <span class="rate-size">96-Gallon</span>
            <span class="rate-price">$225.00/year</span>
          </div>
        </div>
  
        <div class="cart-selection-grid">
          <!-- Waste Carts -->
          <div class="cart-type-section">
            <h3>Waste Carts</h3>
            <div class="cart-input-group">
              <label>
                <span>64-Gallon</span>
                <input 
                  :value="form.AddTr64"
                  @input="emitUpdate({ AddTr64: Number($event.target.value) })"
                  type="number" 
                  min="0" 
                  placeholder="0" 
                />
              </label>
              <div class="cart-total">${{ calculateItemTotal(form.AddTr64, 191.40).toFixed(2) }}</div>
            </div>
            <div class="cart-input-group">
              <label>
                <span>96-Gallon</span>
                <input 
                  :value="form.AddTr96"
                  @input="emitUpdate({ AddTr96: Number($event.target.value) })"
                  type="number" 
                  min="0" 
                  placeholder="0" 
                />
              </label>
              <div class="cart-total">${{ calculateItemTotal(form.AddTr96, 225.00).toFixed(2) }}</div>
            </div>
          </div>
  
          <!-- Recycle Carts -->
          <div class="cart-type-section">
            <h3>Recycle Carts</h3>
            <div class="cart-input-group">
              <label>
                <span>64-Gallon</span>
                <input 
                  :value="form.AddRec64"
                  @input="emitUpdate({ AddRec64: Number($event.target.value) })"
                  type="number" 
                  min="0" 
                  placeholder="0" 
                />
              </label>
              <div class="cart-total">${{ calculateItemTotal(form.AddRec64, 191.40).toFixed(2) }}</div>
            </div>
            <div class="cart-input-group">
              <label>
                <span>96-Gallon</span>
                <input 
                  :value="form.AddRec96"
                  @input="emitUpdate({ AddRec96: Number($event.target.value) })"
                  type="number" 
                  min="0" 
                  placeholder="0" 
                />
              </label>
              <div class="cart-total">${{ calculateItemTotal(form.AddRec96, 225.00).toFixed(2) }}</div>
            </div>
          </div>
  
          <!-- Yard Waste Carts -->
          <div class="cart-type-section">
            <h3>Yard Waste Carts</h3>
            <div class="cart-input-group">
              <label>
                <span>64-Gallon</span>
                <input 
                  :value="form.AddYr64"
                  @input="emitUpdate({ AddYr64: Number($event.target.value) })"
                  type="number" 
                  min="0" 
                  placeholder="0" 
                />
              </label>
              <div class="cart-total">${{ calculateItemTotal(form.AddYr64, 191.40).toFixed(2) }}</div>
            </div>
            <div class="cart-input-group">
              <label>
                <span>96-Gallon</span>
                <input 
                  :value="form.AddYr96"
                  @input="emitUpdate({ AddYr96: Number($event.target.value) })"
                  type="number" 
                  min="0" 
                  placeholder="0" 
                />
              </label>
              <div class="cart-total">${{ calculateItemTotal(form.AddYr96, 225.00).toFixed(2) }}</div>
            </div>
          </div>
        </div>
  
        <!-- Validation message -->
        <div v-if="!hasAdditionalCart" class="validation-message">
          Please select at least one additional cart to continue or deselect this option if you don't need additional carts.
        </div>
  
        <div class="total-summary">
          <span>Total Annual Cost:</span>
          <strong>${{ totalCost.toFixed(2) }}</strong>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Step4AdditionalCarts',
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    computed: {
      hasAdditionalCart() {
        if (this.form.additional !== 'yes') return true;
        
        return (
          this.form.AddTr64 > 0 ||
          this.form.AddTr96 > 0 ||
          this.form.AddRec64 > 0 ||
          this.form.AddRec96 > 0 ||
          this.form.AddYr64 > 0 ||
          this.form.AddYr96 > 0
        );
      },
      totalCost() {
        return (
          this.calculateItemTotal(this.form.AddTr64, 191.4) +
          this.calculateItemTotal(this.form.AddTr96, 225.0) +
          this.calculateItemTotal(this.form.AddRec64, 191.4) +
          this.calculateItemTotal(this.form.AddRec96, 225.0) +
          this.calculateItemTotal(this.form.AddYr64, 191.4) +
          this.calculateItemTotal(this.form.AddYr96, 225.0)
        );
      }
    },
    methods: {
      emitUpdate(updates) {
        this.$emit('update-form', updates);
      },
      calculateItemTotal(quantity, costPerItem) {
        return Number(quantity || 0) * costPerItem;
      }
    }
  };
  </script>