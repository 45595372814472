<template>
  <div class="form-portal">
    <div class="portal-container">
      <!-- Header - Only show when no service is selected -->
      <div v-if="!selectedService" class="form-header">
        <p class="subtitle">Choose your cart options below:</p>
      </div>

      <!-- Service Selection Cards -->
      <div v-if="!selectedService" class="service-grid">


        <!-- Yard Waste -->
        <div class="service-card" :class="{ 'active': hoverIndex === 1 }" @click="selectService('yard-waste')"
          @mouseenter="hoverIndex = 1" @mouseleave="hoverIndex = null">
          <div class="card-content">
            <div class="icon"><img src="@\assets\YardSm.png" alt="Yard Waste" class="elgin-image"
                style="width: auto; height: auto;"></div>
            <h3>Organics and Yard Waste</h3>
            <p>Request an optional yard waste and organics cart</p>
            <div class="arrow-icon">
              <i class="fas fa-arrow-right"></i>
            </div>
          </div>
        </div>

        <!-- Sustainable Options -->
        <div class="service-card" :class="{ 'active': hoverIndex === 2 }" @click="selectService('sustainable')"
          @mouseenter="hoverIndex = 2" @mouseleave="hoverIndex = null">
          <div class="card-content">
            <div class="icon"><img src="@\assets\LandfillSm.png" alt="Reduced Waste" class="elgin-image"
                style="width: auto; height: auto;"></div>
            <h3>Reduced Waste Options</h3>
            <p>Explore environmentally friendly waste management solutions</p>
            <div class="arrow-icon">
              <i class="fas fa-arrow-right"></i>
            </div>
          </div>
        </div>

        <!-- Cart Enrollment -->
        <div class="service-card" :class="{ 'active': hoverIndex === 0 }" @click="selectService('cart-enrollment')"
          @mouseenter="hoverIndex = 0" @mouseleave="hoverIndex = null">
          <div class="card-content">
            <div class="icon"><img src="@\assets\rescart.png" alt="Other Options" class="elgin-image"
                style="width: auto; height: auto;"></div>
            <h3>Extra Services</h3>
            <p>Select or modify your waste, recycling, and yard waste cart services</p>
            <div class="arrow-icon">
              <i class="fas fa-arrow-right"></i>
            </div>
          </div>
        </div>

      </div>

      <!-- Selected Service Form -->
      <div v-else class="selected-service">
        <div class="form-header-with-back">
          <button @click="$router.push('/form')" class="back-button">
            <i class="fas fa-arrow-left"></i>
            Back to services
          </button>
        </div>

        <step-form v-if="selectedService === 'cart-enrollment'" />
        <yard-waste-form v-else-if="selectedService === 'yard-waste'" />
        <sustainable-options-form v-else-if="selectedService === 'sustainable'" />
      </div>

      <!-- Contact Info - Only show on service selection screen -->
      <div v-if="!selectedService" class="contact-info">

      </div>
    </div>
  </div>
</template>

<script>
import StepForm from "@/components/StepForm.vue";
import YardWasteForm from "@/components/YardWasteForm.vue";
import SustainableOptionsForm from "@/components/SustainableOptionsForm.vue";

// In your FormPage component's script section
export default {
  name: "FormPage",
  components: {
    StepForm,
    YardWasteForm,
    SustainableOptionsForm,
  },
  data() {
    return {
      selectedService: null,
      hoverIndex: null
    };
  },
  created() {
    // Get the service from the route path
    const path = this.$route.path;
    if (path.includes('/form/yard-waste')) {
      this.selectedService = 'yard-waste';
    } else if (path.includes('/form/sustainable')) {
      this.selectedService = 'sustainable';
    } else if (path.includes('/form/cart-enrollment')) {
      this.selectedService = 'cart-enrollment';
    }
  },
  methods: {
    selectService(serviceId) {
      this.selectedService = serviceId;
      // Update the URL when selecting a service
      this.$router.push(`/form/${serviceId}`);
    }
  },
  // Watch for route changes
  watch: {
    '$route'(to) {
      const path = to.path;
      if (path === '/form') {
        this.selectedService = null;
      } else if (path.includes('/form/yard-waste')) {
        this.selectedService = 'yard-waste';
      } else if (path.includes('/form/sustainable')) {
        this.selectedService = 'sustainable';
      } else if (path.includes('/form/cart-enrollment')) {
        this.selectedService = 'cart-enrollment';
      }
    }
  }
};
</script>

<style scoped>
.form-portal {
  min-height: 100vh;
  background-color: var(--bg-color, #f8f9fa);
  padding: 1rem;
}

.portal-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.form-header {
  text-align: center;
  margin-bottom: 3rem;
}

.form-header h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-color, #333);
  margin-bottom: 0.5rem;
}

.form-header-with-back {
  margin-bottom: 1rem;
}

.subtitle {
  color: var(--text-secondary, #666);
  font-size: 1.1rem;
}

.service-grid {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 3rem;
}

.service-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.service-card:hover,
.service-card.active {
  transform: translateY(-4px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.card-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.icon-wrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.icon-wrapper i {
  font-size: 1.5rem;
  color: white;
}

.blue {
  background-color: var(--lrs-blue);
}

.green {
  background-color: var(--recycle-green);
}

.teal {
  background-color: var(--safety-orange);
}

.service-card h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--text-primary, #333);
  margin: 0;
}

.service-card p {
  color: var(--text-secondary, #666);
  margin: 0;
  line-height: 1.5;
}

.arrow-icon {
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s ease;
}

.service-card:hover .arrow-icon,
.service-card.active .arrow-icon {
  opacity: 1;
  right: 1rem;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background: transparent;
  border: none;
  color: var(--text-secondary, #666);
  transition: all 0.2s ease;
  cursor: pointer;
}

.back-button:hover {
  color: var(--text-primary, #333);
}

.contact-info {
  text-align: center;
  margin-top: 3rem;
  color: var(--text-secondary, #666);
}

.contact-info a {
  color: var(--primary-blue, #0066cc);
  text-decoration: none;
  margin: 0 0.3rem;
}

.contact-info a:hover {
  text-decoration: underline;
}


/* Add or update these styles in your scoped CSS */
.form-header-with-back {
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1.25rem;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  color: var(--lrs-blue);
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
  background-color: #f8fafc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

.back-button i {
  font-size: 1rem;
  color: var(--lrs-blue);
}

.back-button span {
  font-family: 'Poppins', sans-serif;
}
/* Mobile-first responsive design */
@media (min-width: 640px) {
  .service-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .form-header h1 {
    font-size: 2.2rem;
  }
}

@media (min-width: 1024px) {
  .service-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .portal-container {
    padding: 3rem 2rem;
  }

  .service-card {
    min-height: 280px;
  }
}
</style>