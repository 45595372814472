<template>
    <div class="form-group">
      <h2 class="subheading-1">Yard Waste and Organics Cart Selection</h2>
      
      <div class="info-section">
        <p class="paragraph">
          LRS provides one (1) optional and complimentary 35-gallon, 64-gallon or 96-gallon yard waste and organic cart for each home, upon request. If you'd like one for your home, please select the size below:
        </p>
      </div>
  
      <div class="cart-selection">
        <label for="requestedYardCart" class="form-label"><strong>Select Your Cart Size:</strong></label>
        <select 
          :value="form.requestedYardCart"
          @input="handleCartSelection($event.target.value)"
          id="requestedYardCart"
          class="form-select"
        >
          <option value="35-Gallon">35-Gallon</option>
          <option value="64-Gallon">64-Gallon</option>
          <option value="96-Gallon">96-Gallon</option>
        </select>
      </div>
  
      <div class="cart-details">
        <div class="cart-info">
          <h3>Cart Details:</h3>
          <ul>
            <li>35-Gallon: Ideal for small households or occasional yard waste</li>
            <li>64-Gallon: Perfect for medium-sized yards and regular garden maintenance</li>
            <li>96-Gallon: Best for large properties or extensive landscaping needs</li>
          </ul>
        </div>
        
        <div class="service-info">
          <h3>Service Information:</h3>
          <ul>
            <li>Weekly collection during yard waste season</li>
            <li>Accepts grass clippings, leaves, and small branches</li>
            <li>Organic waste collection available during yard waste season</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Step2YardWasteSelection',
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    emits: ['update-form'],
    methods: {
      handleCartSelection(value) {
        this.$emit('update-form', {
          ...this.form,
          requestedYardCart: value
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .cart-selection {
    margin: 2rem 0;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }
  
  .form-select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  
  .cart-details {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 2rem;
  }
  
  .cart-info, .service-info {
    margin-bottom: 1.5rem;
  }
  
  .cart-info h3, .service-info h3 {
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: var(--primary-color);
  }
  
  .info-section {
    margin-bottom: 2rem;
  }
  </style>