import axios from "axios";

export class NotificationService {
  static async sendEmail(to, subject, body) {
    try {
      const API_URL = process.env.VUE_APP_NOTIFICATION_API_ENDPOINT;
      if (!API_URL) {
        throw new Error("Notification API endpoint not configured");
      }

      const response = await axios.post(API_URL, {
        to,
        subject,
        body,
        isHtml: true
      });
      return response.data;
    } catch (error) {
      console.error("Error sending notification:", error);
      throw error;
    }
  }


  static getFormSubmissionTemplate() {
    return /* html */ `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
          <h2 style="color: #1a73e8;">LRS has received your request!</h2>
          
          <p>Cart size exchanges, additional carts, yard waste and organic cart deliveries will begin in March 2025.</p>
          
          <div style="margin: 20px 0;">
            <h3 style="color: #1a73e8;">Cart Exchange Requests:</h3>
            <ul style="list-style-type: disc; padding-left: 20px;">
              <li style="margin-bottom: 10px;">You will receive a text or phone call the week of delivery to remind you to leave the cart out to be exchanged.</li>
              <li style="margin-bottom: 10px;">You will need to leave your current cart out until it is exchanged on the week of delivery. It may occur after your pick up day.</li>
              <li style="margin-bottom: 10px;">The City will not issue violations or fines during the transition for carts left out.</li>
              <li style="margin-bottom: 10px;">Your first invoice will be mailed after delivery for an upsized waste cart (96-gallon) or additional carts.</li>
            </ul>
          </div>
          
          <div style="margin: 20px 0;">
            <h3 style="color: #1a73e8;">Yard Waste and Organics Cart:</h3>
            <ul style="list-style-type: disc; padding-left: 20px;">
            <li style="margin-bottom: 10px;">You will receive a text or phone call the week of delivery.</li>
              <li style="margin-bottom: 10px;">There is no rental charge for one (1) yard waste and organics cart.</li>
              <li style="margin-bottom: 10px;">Only additional yard waste and organic carts incur a rental charge.</li>
            </ul>
          </div>
           <div style="margin-top: 20px; padding-top: 20px; border-top: 1px solid #eee;">
                <p>If you have any questions, please contact us at:</p>
                <p>Phone: <a href="tel:855-612-7938" style="color: #1a73e8;">855.612.7938</a></p>
                <p>Email: <a href="mailto:elginservice@lrsrecycles.com" style="color: #1a73e8;">ElginService@LRSrecycles.com</a></p>
            </div>
        </div>
     `;
  }

  static getSustainableOptionsTemplate() {


    return /* html */ `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
            <h2 style="color: #1a73e8;">LRS has received your sustainable options request!</h2>
            
            <p>Thank you for choosing sustainable options for your waste management. 
            
            <p>Our team will process your request and contact you with further details about implementation and next steps.</p>
            
            <div style="margin-top: 20px; padding-top: 20px; border-top: 1px solid #eee;">
                <p>If you have any questions, please contact us at:</p>
                <p>Phone: <a href="tel:855-612-7938" style="color: #1a73e8;">855.612.7938</a></p>
                <p>Email: <a href="mailto:elginservice@lrsrecycles.com" style="color: #1a73e8;">ElginService@LRSrecycles.com</a></p>
            </div>
        </div>
    `;
   

  }

  static getYardWasteCartTemplate() {

    return /* html */ `
        <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px;">
            <h2 style="color: #1a73e8;">LRS has received your yard waste cart request!</h2>
            
            <p>Thank you for choosing a yard waste cart. 
            
            <p>Our team will process your request and contact you with further details about delivery and next steps.</p>
            
                  <div style="margin-top: 20px; padding-top: 20px; border-top: 1px solid #eee;">
                <p>If you have any questions, please contact us at:</p>
                <p>Phone: <a href="tel:855-612-7938" style="color: #1a73e8;">855.612.7938</a></p>
                <p>Email: <a href="mailto:elginservice@lrsrecycles.com" style="color: #1a73e8;">ElginService@LRSrecycles.com</a></p>
            </div>
        </div>
    `;
  }
}
