// components/ToggleSwitch.vue
<template>
  <div class="toggle-container">
    <label class="toggle-label">
      <span><strong>{{ label }}</strong></span>
      <div class="toggle-switch">
        <input 
          type="checkbox"
          :checked="modelValue"
          @change="$emit('update:modelValue', $event.target.checked)"
        />
        <span class="slider"></span>
      </div>
    </label>
    <small v-if="hint">{{ hint }}</small>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    label: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:modelValue']
}
</script>