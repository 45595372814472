<!-- AzureMap.vue -->
<template>
    <div class="map-container">
        <div id="myMap"></div>
        <!-- Enhanced Tooltip/Info Card -->
        <div v-if="activeLocation" class="location-card" :style="popupStyle">
            <div class="card-header">
                <h3>{{ activeLocation.name }}</h3>
                <button @click="closeCard" class="close-button">&times;</button>
            </div>
            <div class="card-content">
                <p>{{ activeLocation.address }}</p>
                <div class="card-actions">
                    <a :href="getDirectionsUrl(activeLocation)" target="_blank" rel="noopener noreferrer"
                        class="directions-link">
                        Get Directions
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AzureMap',
    props: {
        locations: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            map: null,
            mapLoaded: false,
            activeLocation: null,
            popupStyle: {
                top: '0px',
                left: '0px'
            },
            selectedPin: null
        }
    },
    async mounted() {
        await this.loadAzureMaps()
        this.initializeMap()
    },
    methods: {
        loadAzureMaps() {
            return new Promise((resolve) => {
                const script = document.createElement('script')
                script.src = 'https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.js'
                script.async = true
                script.onload = () => {
                    const link = document.createElement('link')
                    link.rel = 'stylesheet'
                    link.href = 'https://atlas.microsoft.com/sdk/javascript/mapcontrol/2/atlas.min.css'
                    document.head.appendChild(link)
                    resolve()
                }
                document.body.appendChild(script)
            })
        },
        initializeMap() {
            if (!window.atlas) return

            this.map = new window.atlas.Map('myMap', {
                center: [-88.2851567, 42.03956],
                zoom: 12,
                style: 'road',
                authOptions: {
                    authType: 'subscriptionKey',
                    subscriptionKey: '8PlUo4hwIMw5Z4YiqxExu8JVAT1cPhdjGerAg59vScam6Ta86WyUJQQJ99AKACrJL3JG6R9WAAAgAZMP1VFu'
                }
            })

            this.map.events.add('ready', () => {
                this.addPoints()
            })
        },
        // In AzureMap.vue, update the addPoints method:
        // Add this new method to the methods section of AzureMap.vue

        simulatePointClick(location) {
            if (!location || !this.map) return;

            // Get the data source
            const dataSource = this.map.sources.getById('locations');
            if (!dataSource) return;

            // Reset all pins to unselected
            dataSource.getShapes().forEach((shape) => {
                shape.setProperties({
                    selected: false
                });
            });

            // Find the matching shape
            const shapes = dataSource.getShapes();
            const targetShape = shapes.find(shape => {
                const coords = shape.getCoordinates();
                return coords[0] === location.coordinates[0] && coords[1] === location.coordinates[1];
            });

            if (targetShape) {
                // Mark the shape as selected
                targetShape.setProperties({
                    selected: true
                });

                // Update active location
                this.activeLocation = {
                    name: location.name,
                    address: location.address,
                    coordinates: location.coordinates
                };

                // Calculate popup position using the shape's coordinates
                const pixelPosition = this.map.positionsToPixels([location.coordinates])[0];
                this.popupStyle = {
                    left: `${pixelPosition[0]}px`,
                    top: `${pixelPosition[1] - 20}px`,
                    transform: 'translate(-50%, -100%)'
                };
            }
        },
        addPoints() {
            if (!this.map || !window.atlas) return;

            // Create a new DataSource
            const dataSource = new window.atlas.source.DataSource('locations'); // Add an ID to the datasource
            this.map.sources.add(dataSource);

            // Add points to the DataSource
            // In the addPoints method, modify how points are added:
            this.locations.forEach((loc) => {
                const point = new window.atlas.data.Feature(
                    new window.atlas.data.Point(loc.coordinates),
                    {
                        name: loc.name,
                        address: loc.address,
                        coordinates: loc.coordinates,
                        selected: false,
                        // Add any other properties you need to display in the card
                        ...loc  // This will include any additional properties from your location data
                    }
                );
                dataSource.add(point);
            });


            // Symbol layer for default pins
            const symbolLayer = new window.atlas.layer.SymbolLayer(dataSource, null, {
                iconOptions: {
                    image: 'pin-round-red',
                    allowOverlap: true,
                    size: 0.8
                }
            });

            // Symbol layer for selected pins
            const selectedLayer = new window.atlas.layer.SymbolLayer(dataSource, null, {
                iconOptions: {
                    image: 'pin-round-darkblue',
                    allowOverlap: true,
                    size: 1
                },
                filter: ['==', ['get', 'selected'], true]
            });

            // Add layers to the map
            this.map.layers.add([symbolLayer, selectedLayer]);

            // Inside addPoints method, modify the click handler:
            this.map.events.add('click', symbolLayer, (e) => {
                if (e.shapes && e.shapes.length > 0) {
                    const clickedShape = e.shapes[0];

                    // Debug the properties
                    console.log('Clicked shape properties:', clickedShape.getProperties());

                    // Ensure we're getting all properties from the original location data
                    const locationData = this.locations.find(loc =>
                        loc.coordinates[0] === clickedShape.getCoordinates()[0] &&
                        loc.coordinates[1] === clickedShape.getCoordinates()[1]
                    );

                    // Combine shape properties with original location data
                    const props = {
                        ...clickedShape.getProperties(),
                        ...locationData
                    };

                    // Reset all pins to unselected
                    dataSource.getShapes().forEach((shape) => {
                        shape.setProperties({
                            selected: false
                        });
                    });

                    // Mark the clicked pin as selected
                    clickedShape.setProperties({
                        selected: true
                    });

                    // Make sure we have all required properties before setting activeLocation
                    if (props.name && props.address) {
                        this.activeLocation = { ...props };
                    } else {
                        console.error('Missing required properties:', props);
                    }

                    // Calculate popup position
                    const pixelPosition = this.map.positionsToPixels([clickedShape.getCoordinates()])[0];
                    this.popupStyle = {
                        left: `${pixelPosition[0]}px`,
                        top: `${pixelPosition[1] - 20}px`,
                        transform: 'translate(-50%, -100%)'
                    };
                }
            });


            // Handle hover effect (optional)
            this.map.events.add('mouseover', symbolLayer, () => {
                this.map.getCanvasContainer().style.cursor = 'pointer';
            });

            this.map.events.add('mouseout', symbolLayer, () => {
                this.map.getCanvasContainer().style.cursor = '';
            });
        }

        ,
        centerMap(coordinates) {
            if (this.map) {
                this.map.setCamera({
                    center: coordinates,
                    zoom: 15,
                    type: 'fly',
                    duration: 1000
                })
            }
        },
        closeCard() {
            this.activeLocation = null; // Clear active location
            const dataSource = this.map.sources.getById('locations');
            if (dataSource) {
                dataSource.getShapes().forEach((shape) => {
                    shape.setProperties({
                        selected: false // Reset selected state
                    });
                });
            }
        }
        ,
        getDirectionsUrl(location) {
            const address = encodeURIComponent(location.address)
            return `https://www.google.com/maps/dir/?api=1&destination=${address}`
        }
    }
}
</script>

<style scoped>
.map-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 480px;
}

#myMap {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.location-card {
    position: absolute;
    background: white;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    transition: all 0.3s ease;
}

.card-header {
    padding: 12px 16px;
    background: #f8f9fa;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
}

.card-header h3 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    color: #666;
    cursor: pointer;
    padding: 0 4px;
}

.close-button:hover {
    color: #333;
}

.card-content {
    padding: 16px;
}

.card-content p {
    margin: 0 0 12px 0;
    color: #666;
}

.card-actions {
    display: flex;
    justify-content: flex-end;
}

.directions-link {
    display: inline-block;
    padding: 8px 16px;
    background: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.2s;
}

.directions-link:hover {
    background: #0056b3;
}
</style>