<template>
    <div>
      <!-- Introduction -->
      <div class="form-group">
        <h2 class="subheading-1">Review & Submit</h2>
        <p class="paragraph">
          Please review your enrollment selections below. If you'd like to change anything, use the "Previous" button
          below to go back to that step and make the needed changes. Once you have submitted your changes a confirmation will be
          sent to the email address provided. If you have any questions or need to make any changes please contact us at
          <a href="tel:855-612-7938">855.612.7938</a> or 
          <a href="mailto:elginservice@lrsrecycles.com">ElginService@LRSrecycles.com</a>.
        </p>
      </div>
  
      <!-- Service Address -->
      <div class="form-group">
        <h2 class="subheading-1">Service Address</h2>
        <p><strong>Address:</strong> {{ form.address }}</p>
      </div>
  
      <!-- Cart Selection -->
      <div class="form-group">
        <h2 class="subheading-1">Cart Selection</h2>
        <p><strong>Waste Cart Size:</strong> {{ form.requestedWasteCart }}</p>
        <p><strong>Recycle Cart Size:</strong> {{ form.requestedRecycleCart }}</p>
        <p><strong>Yard Waste and Organics Cart Size:</strong> {{ form.requestedYardCart }}</p>
      </div>
  
      <!-- 96-Gallon Cart Acknowledgement -->
      <div v-if="showUpsizeAcknowledgement" class="form-group">
        <strong>96-Gallon Cart Upsize Rate Acknowledgement</strong>
        <p class="paragraph">
          I understand that requesting a 96-gallon waste cart will result in an annual fee of $33.80, paid in advance, and
          based on the cart delivery date. No credits or refunds will be issued for cancellations made before the one-year
          anniversary of enrollment.
        </p>
        <div>
          <label>
            <input 
              type="checkbox"
              :checked="form.UpsizeAck === 'I Accept'"
              @change="emitUpdate({ UpsizeAck: $event.target.checked ? 'I Accept' : '' })"
            />
            I Accept
          </label>
        </div>
      </div>
  
      <!-- Additional Cart Review -->
      <div class="form-group">
        <h2 class="subheading-1">Additional Carts</h2>
        <table border="1" cellpadding="10" cellspacing="0" style="width: 100%; text-align: center;">
          <thead>
            <tr>
              <th>Item</th>
              <th>Qty</th>
              <th>Cost</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <!-- WASTE Carts -->
            <tr v-if="form.AddTr64 > 0 || form.AddTr96 > 0">
              <td><strong>WASTE/64-Gallon</strong></td>
              <td>{{ form.AddTr64 }}</td>
              <td>$191.40</td>
              <td>${{ calculateItemTotal(form.AddTr64, 191.40).toFixed(2) }}</td>
            </tr>
            <tr v-if="form.AddTr96 > 0">
              <td><strong>WASTE/96-Gallon</strong></td>
              <td>{{ form.AddTr96 }}</td>
              <td>$225.00</td>
              <td>${{ calculateItemTotal(form.AddTr96, 225.00).toFixed(2) }}</td>
            </tr>
            <!-- RECYCLE Carts -->
            <tr v-if="form.AddRec64 > 0">
              <td><strong>RECYCLE/64-Gallon</strong></td>
              <td>{{ form.AddRec64 }}</td>
              <td>$191.40</td>
              <td>${{ calculateItemTotal(form.AddRec64, 191.40).toFixed(2) }}</td>
            </tr>
            <tr v-if="form.AddRec96 > 0">
              <td><strong>RECYCLE/96-Gallon</strong></td>
              <td>{{ form.AddRec96 }}</td>
              <td>$225.00</td>
              <td>${{ calculateItemTotal(form.AddRec96, 225.00).toFixed(2) }}</td>
            </tr>
            <!-- YARD Carts -->
            <tr v-if="form.AddYr64 > 0">
              <td><strong>YARD/64-Gallon</strong></td>
              <td>{{ form.AddYr64 }}</td>
              <td>$191.40</td>
              <td>${{ calculateItemTotal(form.AddYr64, 191.40).toFixed(2) }}</td>
            </tr>
            <tr v-if="form.AddYr96 > 0">
              <td><strong>YARD/96-Gallon</strong></td>
              <td>{{ form.AddYr96 }}</td>
              <td>$225.00</td>
              <td>${{ calculateItemTotal(form.AddYr96, 225.00).toFixed(2) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="3" style="text-align: right;"><strong>Total Cost: </strong></td>
              <td><strong>${{ totalCost.toFixed(2) }}</strong></td>
            </tr>
          </tfoot>
        </table>
      </div>
  
      <!-- Annual Renewal Acknowledgement -->
      <div class="form-group">
        <h2 class="subheading-1">Annual Renewal, Billing, and Cancellation Acknowledgement</h2>
        <p class="paragraph">
          I acknowledge and understand I will receive an invoice from LRS on an annual basis and there will be no refunds
          or credits issued for early termination. Cancellation must be received by December 1st of the current year to
          avoid recurring rental charges for the following year.
        </p>
        <div>
          <label class="toggle-container">
          <input 
            type="checkbox"
              :checked="form.RenewalAck === 'I Accept'"
              @change="emitUpdate({ RenewalAck: $event.target.checked ? 'I Accept' : '' })"
            />
            <span class="toggle-label">I Accept</span>
          </label>
        </div>
      </div>
  
      <!-- Billing Information -->
      <div class="form-group">
        <h2 class="subheading-1">Billing Information </h2>
        <label class="toggle-container">
          <input 
            type="checkbox"
            :checked="form.BillingDifferent === 'Billing Different'"
            @change="emitUpdate({ BillingDifferent: $event.target.checked ? 'Billing Different' : '' })"
          />
          <span class="toggle-label">Billing address is different.</span>
        </label>

  
        <div v-if="form.BillingDifferent === 'Billing Different'" class="billing-form">
          <div class="form-group">
            <label for="billingattention"><strong>Attention To:</strong></label>
            <input 
              :value="form.billingattention"
              @input="emitUpdate({ billingattention: $event.target.value })"
              type="text"
              id="billingattention"
              placeholder="Enter billing name"
            />
  
            <label for="billingaddress1"><strong>Address 1:</strong></label>
            <input 
              :value="form.billingaddress1"
              @input="emitUpdate({ billingaddress1: $event.target.value })"
              type="text"
              id="billingaddress1"
              placeholder="Enter billing address"
            />
  
            <label for="billingaddress2"><strong>Address 2:</strong></label>
            <input 
              :value="form.billingaddress2"
              @input="emitUpdate({ billingaddress2: $event.target.value })"
              type="text"
              id="billingaddress2"
              placeholder="Enter additional address details"
            />
  
            <label for="billingcity"><strong>City:</strong></label>
            <input 
              :value="form.billingcity"
              @input="emitUpdate({ billingcity: $event.target.value })"
              type="text"
              id="billingcity"
              placeholder="Enter billing city"
            />
  
            <label for="billingstate"><strong>State:</strong></label>
            <input 
              :value="form.billingstate"
              @input="emitUpdate({ billingstate: $event.target.value })"
              type="text"
              id="billingstate"
              placeholder="Enter billing state"
            />
  
            <label for="billingzip"><strong>Zip Code:</strong></label>
            <input 
              :value="form.billingzip"
              @input="emitUpdate({ billingzip: $event.target.value })"
              type="text"
              id="billingzip"
              placeholder="Enter billing zip code"
            />
          </div>
        </div>
      </div>
  
      <!-- Completed By -->
      <div class="form-group">
        <label><strong>Completed By:</strong></label>
        <div>
          <label>
            <input 
              type="radio"
              :checked="form.completedBy === 'Resident'"
              @change="emitUpdate({ completedBy: 'Resident' })"
            />
            Resident
          </label>
          <label>
            <input 
              type="radio"
              :checked="form.completedBy === 'LRS Customer Service'"
              @change="emitUpdate({ completedBy: 'LRS Customer Service' })"
            />
            LRS Customer Service
          </label>
          <label>
            <input 
              type="radio"
              :checked="form.completedBy === 'Elgin Neighborhood Services'"
              @change="emitUpdate({ completedBy: 'Elgin Neighborhood Services' })"
            />
            Elgin Customer Service
          </label>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Step5ReviewSubmit',
    props: {
      form: {
        type: Object,
        required: true
      }
    },
    computed: {
      showUpsizeAcknowledgement() {
        const wasteCart = (this.form.requestedWasteCart || "").trim().toLowerCase();
        const recycleCart = (this.form.requestedRecycleCart || "").trim().toLowerCase();
        return wasteCart === "96-gallon" || recycleCart === "96-gallon";
      },
      totalCost() {
        return (
          this.calculateItemTotal(this.form.AddTr64, 191.4) +
          this.calculateItemTotal(this.form.AddTr96, 225.0) +
          this.calculateItemTotal(this.form.AddRec64, 191.4) +
          this.calculateItemTotal(this.form.AddRec96, 225.0) +
          this.calculateItemTotal(this.form.AddYr64, 191.4) +
          this.calculateItemTotal(this.form.AddYr96, 225.0)
        );
      }
    },
    methods: {
      emitUpdate(updates) {
        this.$emit('update-form', updates);
      },
      calculateItemTotal(quantity, costPerItem) {
        return Number(quantity || 0) * costPerItem;
      }
    }
  };
  </script>