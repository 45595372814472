// components/ContactMethodSelect.vue
<template>
  <div class="contact-methods">
    <strong>{{ label }}</strong>
    <div class="contact-options">
      <div
        v-for="option in options"
        :key="option"
        class="contact-option"
        :class="{ selected: isSelected(option) }"
        @click="toggleOption(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactMethodSelect',
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Array,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  methods: {
    isSelected(option) {
      return this.modelValue.includes(option);
    },
    toggleOption(option) {
      const updatedValue = [...this.modelValue];
      const index = updatedValue.indexOf(option);
      
      if (index === -1) {
        updatedValue.push(option);
      } else {
        updatedValue.splice(index, 1);
      }
      
      this.$emit('update:modelValue', updatedValue);
    }
  }
}
</script>