<template>
  <div id="app">
    <nav class="app-bar navy">
      <a href="/" class="brand no-hover">
        <img src="./assets/lrs.png" alt="Logo" class="logo" />
      </a>
      <div class="site-title">
        <h1>City of Elgin, IL Resident Portal</h1>
      </div>
      <div class="nav-controls">
        <language-selector />
        <ul class="app-bar-menu" :class="{ 'is-visible': menuOpen }">
          <li v-for="section in sections" :key="section.link" class="nav-item">
            <router-link :to="section.link" class="nav-link" @click="menuOpen = false">
              {{ section.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <!-- Floating menu button -->
    <button class="floating-menu" @click="toggleMenu">
      <span class="floating-menu-icon">☰</span>
    </button>

    <router-view></router-view>
  </div>
</template>

<script>
import LanguageSelector from './components/LanguageSelector.vue';

export default {
  name: "App",
  components: {
    LanguageSelector
  },
  data() {
    return {
      menuOpen: false,
      sections: [
        { name: "Home", link: "/" },
        { name: "Cart Options", link: "/form" },
       // { name: "Damaged & Broken Carts", link: "/damaged" },
        { name: "Services", link: "/service"},
        { name: "Trash Sticker Locations", link: "/stickers" },
        { name: "Contact Us", link: "/contact" },
      ],
    };
  },
  methods: {
    toggleMenu() {
      console.log("Menu toggled. Current state:", this.menuOpen);
      this.menuOpen = !this.menuOpen;
    },
  },
  watch: {
    menuOpen(newValue) {
      console.log("menuOpen state changed:", newValue);
    },
  },
};
</script>

<style>
.nav-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Add these styles to your existing CSS */
@media (max-width: 768px) {
  .nav-controls {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .language-selector {
    margin: 10px 0;
    width: 100%;
  }
}

/* Ensure the Google Translate dropdown is visible on mobile */
@media (max-width: 768px) {
  .goog-te-combo {
    width: 100%;
    max-width: 200px;
  }
}
</style>