<template>
  <div class="elgin-resident-portal">
    <div class="header-section">
      <h1 class="h1">TRASH STICKER LOCATIONS</h1>
      <p class="paragraph">
        Starting January 1, 2025, the sticker rate will be $4.00 each. New stickers will be available for purchase on
        December 18, 2024. LRS will honor WM stickers through March 31, 2025. Stickers are required for bulk items,
        excess bags and resident-owned containers.
      </p>

      <img src="@\assets\Elgin Sticker 1.jpg" alt="Home Page" class="responsive-image" width="600px" >


    </div>

    <div class="map-section">
      <div class="locations-sidebar">
        <button class="toggle-sidebar" @click="sidebarOpen = !sidebarOpen">
          {{ sidebarOpen ? '←' : '→' }} Locations
        </button>
        <div class="locations-list" :class="{ 'sidebar-open': sidebarOpen }">
          <div v-for="(locations, store) in storeLocations" :key="store" class="store-group">
            <h2>{{ store }}</h2>
            <ul>
              <li v-for="location in locations" :key="location.address">
                <a 
                  href="#" 
                  @click.prevent="handleLocationClick(store, location)"
                  @mouseenter="highlightLocation(location)"
                  @mouseleave="clearHighlight"
                >
                  {{ location.address }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="map-container">
        <azure-map ref="azureMap" :locations="mapLocations" />
      </div>
    </div>
  </div>
</template>

<script>
import AzureMap from '@/components/AzureMap.vue'

export default {
  name: 'StickersPage',
  components: {
    AzureMap
  },
  data() {
    return {
      sidebarOpen: true,
      storeLocations: {
        'Elgin City Hall': [
          { address: '150 Dexter Ct., Elgin', coordinates: [-88.2851567, 42.03956] }
        ],
        'Meijer': [
          { address: '815 S Randall Rd., Elgin', coordinates: [-88.334489, 42.0124] }
        ],
        'Caputo\'s Fresh Market': [
          { address: '622 S Randall Rd., South Elgin', coordinates: [-88.3407466, 41.9709261] }
        ],
        'Ziegler\'s Ace Hardware Stores': [
          { address: '215 N Spring St., Elgin', coordinates: [-88.2814704, 42.0411611] },
          { address: '1158 Lilian St., Elgin', coordinates: [-88.3077568, 42.026083] },
          { address: '301 S Randall Rd., Elgin', coordinates: [-88.3387913, 42.025259] }
        ],
        'Jewel-Osco Stores': [
          { address: '1040 Summit Ave., Elgin', coordinates: [-88.2526587, 42.048073] },
          { address: '1660 Larkin Ave., Elgin', coordinates: [-88.319361, 42.0341695] },
          { address: '375 Randall Rd., South Elgin', coordinates: [-88.3389029, 41.9869936] }
        ]
      }
    }
  },
  computed: {
    mapLocations() {
      const locations = []
      Object.entries(this.storeLocations).forEach(([store, locs]) => {
        locs.forEach(loc => {
          locations.push({
            ...loc,
            name: store
          })
        })
      })
      return locations
    }
  },
  methods: {
    handleLocationClick(store, location) {
      // Center the map on the location
      this.centerMapOn(location.coordinates);

      // Find the corresponding map location to simulate a pin click
      const mapLocation = this.mapLocations.find(
        loc => loc.coordinates[0] === location.coordinates[0] && 
              loc.coordinates[1] === location.coordinates[1]
      );

      // Trigger the map's point click behavior
      if (this.$refs.azureMap) {
        this.$refs.azureMap.simulatePointClick(mapLocation);
      }
    },
    centerMapOn(coordinates) {
      if (this.$refs.azureMap) {
        this.$refs.azureMap.centerMap(coordinates)
      }
    },
    highlightLocation(location) {
      if (this.$refs.azureMap && this.$refs.azureMap.map) {
        this.centerMapOn(location.coordinates)
      }
    },
    clearHighlight() {
      // Clear highlight if needed
    }
  }
}
</script>

<style scoped>
.elgin-resident-portal {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
}

.header-section {
  margin-bottom: 20px;
}

.map-section {
  position: relative;
  display: flex;
  flex: 1;
  min-height: 600px;
  gap: 0;
}

.locations-sidebar {
  position: relative;
  z-index: 2;
}

.toggle-sidebar {
  position: relative;
  display: block;
  margin-bottom: 10px; /* Space between button and the list */
  padding: 8px 12px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%; /* Ensures full width in sidebar */
}

.locations-list {
  background: white;
  width: 100%;
  height: calc(100% - 50px); /* Adjust for button height */
  overflow-y: auto;
  padding: 10px 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.locations-list.sidebar-open {
  transform: translateX(0);
  transition: transform 0.3s ease;
}


.map-container {
  flex: 1;
  height: 100%;
}

.store-group {
  margin-bottom: 20px;
}

.store-group h2 {
  color: var(--lrs-blue);
  font-size: 1.2em;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  margin-bottom: 8px;
}

a {
  color: #333;
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: var(--lrs-blue);
}

@media (max-width: 768px) {
  .map-section {
    flex-direction: column;
    min-height: 400px;
  }

  .locations-list {
    width: 100%;
    position: relative;
    transform: none;
    max-height: 200px;
    margin-bottom: 20px;
  }

  .toggle-sidebar {
    display: none;
  }
}
</style>