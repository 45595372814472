import axios from "axios";

const API_URL = process.env.VUE_APP_API_GETADDRESS;

export const fetchAddresses = async (searchTerm) => {
  try {
    if (!searchTerm || searchTerm.length < 3) {
      return [];
    }

    const response = await axios.post(API_URL, {
      searchTerm: searchTerm,
      OnlyAddress: true,
    });

    return response.data.map((item) => ({
      id: item.ItemInternalId,
      display: `${item.CM04_ADD2}, ${item.CM04_CITY}, ${item.CM04_STAT}, ${item.CM04_PZIP}, ${item.CM04_FLD3}`.replace(/,\s*$/, ""),
      siteNumber: item.CM04_SITE,
      county: item.CM04_FLD3,
      // Add individual fields for better data handling
      street: item.CM04_ADD2,
      city: item.CM04_CITY,
      state: item.CM04_STAT,
      zip: item.CM04_PZIP
    }));
  } catch (error) {
    console.error("Error fetching addresses:", error);
    return [];
  }
};