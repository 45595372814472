<template>
  
    <section id="contact-us" class="section">
      <div class="container">
        <h1>Contact Us</h1>
        <p>
          For inquiries, please email us at
          <a href="mailto:elginservice@lrsrecycles.com">elginservice@lrsrecycles.com</a>.
        </p>
      </div>
    </section>
  </template>