<template>
  <div class="elgin-resident-portal">



    <!-- Section 1 -->
    <section class="responsive-section">
      <div class="column column-1">
        <!-- Column 1 Content -->

        <h1 class="h1">Welcome to LRS!</h1>
        <p class="paragraph">
          We are honored to be your new service provider beginning January 1, 2025! We are committed to providing you
          with timely, reliable and sustainable services. We are excited to announce some upcoming changes with the new
          program!
        </p><br>
        <div class="accordion-container">
  <div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\rescart.png" alt="Recycle Guide" class="elgin-image" style="width: auto; height: auto;"></div>
      <span><h3 class="h3">WHAT’S STAYING THE SAME?</h3></span>
      <div class="toggle-icon">+</div>
    </div>
    <div class="accordion-content">
        <ul>
          <li>Collection day will stay the same</li>
          <li>City of Elgin gray and blue carts will still be used</li>
          <li>Electronic and Household Hazardous Waste Collection will still be provided</li>
          <li>Base service will continue to be billed on your monthly utility bill</li>
        </ul>

    </div>
  </div>

  <div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\rescart.png" alt="Recycle Guide" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3 class="h3">WHAT'S CHANGING?</h3></span>
      <div class="toggle-icon">+</div>
    </div>
    <div class="accordion-content">
             <ul>
          <li>Garbage, recycle and yard waste pickup times will vary</li>
          <li>Yard waste stickers are no longer required</li>
          <li>Free yard waste and organic carts are optional upon enrollment</li>
          <li>Limit of two (2) bulk items each week with stickers required</li>
          <li>Stickers will be required for garbage outside of Elgin and LRS carts</li>
          <li>WM trash stickers will be honored through March 31, 2025</li>
          <li>Program rates will be based on cart sizes</li>
          <li>Rate information available on this site</li>
        </ul><br>
    </div>
  </div>
  
  
  </div>
       
<br>
        <h3 class="subheading-1"> If you are satisfied with the carts you have, <strong>NO ACTION IS REQUIRED.</strong></h3>
<br>
        <h2 class="h2">CART OPTIONS</h2>

        <div class="accordion-container">

<div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\YardSm.png" alt="Yard Waste" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3a class="h3a"><strong> ORGANICS</strong></h3a></span>
  <div class="toggle-icon">+</div>
</div>
<div class="accordion-content">
  <p class="paragraph">
Upon request, LRS provides <strong>one (1)</strong> complimentary yard waste and organics cart for each home. Additional carts are available for a monthly fee. See Extra Cart Services to submit a request for an additional cart.<br><br>
Yard Waste and Organics Available Cart Sizes:
<ul>
<li>35-Gallon</li>
<li>64-Gallon</li>
<li>96-Gallon</li>
</ul>
</p><br>
<router-link 
  to="/form/yard-waste" 
  class="lrs-button" 
  style="width: 275px;">
  Request Yard Waste & Organics Cart Here
</router-link>
    
</div>
</div>

<div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\LandfillSm.png" alt="Garbage" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3a class="h3a"><strong>REDUCED WASTE 35-GALLON CART OPTION</strong></h3a></span>
  <div class="toggle-icon">+</div>
</div>
<div class="accordion-content">
  <p class="paragraph">
    If you do not generate more then two (2) trash bags each week, this option may work for you. Additional trash placed outside of the cart requires Elgin Trash Sticker(s). Please select the Downsize Waste Cart to 35-Gallon below if you'd like to make this change.<br><br>
Monthly rates effective January 1, 2025, billed with utility-water bill from City of Elgin:
<ul>
<li>35-Gallon: $22.50</li>
<li>64-Gallon: $24.95</li>
</ul>
</p><br>
<router-link 
  to="/form/sustainable" 
  class="lrs-button" 
  style="width: 275px;">
  Change Waste Cart Size Here
</router-link>

</div>
</div>

<div class="accordion-item">
    <div class="accordion-header">
      <div class="icon"><img src="@\assets\rescart.png" alt="Other Options" class="elgin-image" style="width: auto; height: auto;"></div>
  <span><h3a class="h3a"><strong>EXTRA SERVICES</strong></h3a></span>      <div class="toggle-icon">+</div>
</div>
<div class="accordion-content">
  <p class="paragraph">
LRS offers Elgin homes the option to up-size your base service waste cart to a 96-gallon for a monthly charge of $2.80. Residents may also request additional carts for Waste, Recycle, Yard Waste and Organics, upon request. Both are annually billed from LRS.<br><br>
Monthly Rates Per Cart: (billed on an annual basis)
<ul>
<li>64-Gallon: $15.95</li>
<li>96-Gallon: $18.75</li>
</ul>
</p><br>
<router-link 
  to="/form/cart-enrollment" 
  class="lrs-button" 
  style="width: 275px;">
  Request Extra Services Here
</router-link>
   
</div>
</div>
  </div>


      </div>

      <div class="column column-2">
        <!-- Column 2 Content -->
        <div class="contact-image">
      <img src="@\assets\CurattoFLTruck_Resi_3.jpg" alt="LRS Truch" class="responsive-image">
    </div>
<br>
        <h3b class="h3b">REQUEST SERVICES</h3b>
    <br><br>
        <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form';">Organics</button><br><br>
          <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form';">Reduced Waste <br>35-Gallon Waste Cart</button><br><br>
            <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/form';">Extra LRS Services</button><br><br>
        <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/damaged';">Replace Damaged<br>or Broken Carts</button><br><br>
        <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/ewaste';">Schedule Electronic<br>Waste Pickup</button><br><br>
        <button class="lrs-button" style="width: 275px;" onclick="window.location.href='/hhw';">Schedule Household<br>Hazardous Waste Pickup</button><br><br>

      </div>
    </section>

    <!-- Section 2 -->
    <section class="section-2">
      <hr class="horizontal-line">
    </section>

    <!-- Section 3 -->
    <section class="section-3">

      <div class="column column-3">
        <h3a class="h3a"><strong>City of Elgin</strong></h3a><br>
        <button class="lrs-button" style="width: 275px;">By Phone at <br><a
            href="tel:847-931-6001">ELGIN 311 / 847.931.6001</a></button><br><br>
        <button class="lrs-button" style="width: 275px;">By Email at <a
          href="mailto:elgin311@cityofelgin.org">elgin311@cityofelgin.com</a></button>


      </div>

      <div class="column column-4">

        <h3a class="h3a"><strong>LRS</strong></h3a><br>
        <button class="lrs-button" style="width: 275px;">By Phone at<br> <a
            href="tel:855-612-7938">855.612.7938</a></button><br><br>
        <button class="lrs-button" style="width: 275px;">By Email at <a
            href="mailto:elginservice@lrsrecycles.com">ElginService@LRSrecycles.com</a></button>

       </div>

      <div class="column column-5">
        <h3a class="h3a">Download Brochure</h3a><br>
        <img src="@\assets\brochure.png" alt="Elgin Brochure" class="image">

      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  mounted() {
    // Add event listeners for the accordion when the component is mounted
    document.querySelectorAll('.accordion-header').forEach((header) => {
      header.addEventListener('click', () => {
        const item = header.parentElement;
        const content = item.querySelector('.accordion-content');

        // Toggle visibility
        if (content.style.display === 'block') {
          content.style.display = 'none';
          header.querySelector('.toggle-icon').textContent = '+';
        } else {
          content.style.display = 'block';
          header.querySelector('.toggle-icon').textContent = '-';
        }
      });
    });
  },
};
</script>

<style scoped>


/* Section Layouts for Different Sizes */
.section-1,
.section-3 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Columns Configuration */
.column {
  box-sizing: border-box;
  padding: 20px;
  flex: 1 1 auto;
  min-width: 200px;
  /* Ensure columns do not shrink too much */
}

.column-1 {
  flex: 1 1 60%;
}

.column-2 {
  flex: 1 1 35%;

}

/* Responsive Column Adjustments */
@media (max-width: 768px) {

  .column-1,
  .column-2,
  .column-3,
  .column-4,
  .column-5 {
    flex: 1 1 100%;
    text-align: left;
    /* Adjust text alignment for mobile */
  }
.h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 32px;
    color: var(--lrs-blue);
}

.h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: var(--recycle-green);
    margin-top: 10px;
    margin-bottom: 10px;
}
.subheading-1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: var(--safety-orange);
}
.h3a {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: var(--lrs-blue);
}
.h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: var(--recycle-green);
}
.accordion-content {
    display: none; /* Collapsed by default */
  }

  .accordion-header {
    cursor: pointer; /* Make the header clickable */
  }
}

/* Section Divider */
.section-2 {
  margin: 20px 0;
}

.horizontal-line {
  height: 5px;
  background-color: var(--lrs-blue);
  border: none;
}

/* Cards Styling and Responsive Configurations */
.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1 1 auto;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Card Titles, Descriptions, and Links */
.card-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--lrs-blue);
  margin-bottom: 10px;
}

.card-description {
  font-size: 16px;
  color: #555555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.card-link {
  font-size: 16px;
  font-weight: 600;
  color: var(--recycle-green);
  text-decoration: none;
  transition: color 0.3s ease;
}

.card-link:hover {
  color: var(--safety-orange);
}

/* Contact Buttons Styles */
.contact-button {
  width: 100%;
  max-width: 275px;
}

/* Image Styles for Responsiveness */
.responsive-image {
  max-width: 100%;
  height: auto;
}

/* List Styling */
.list {
  list-style: inside;
  padding-left: 20px;
  margin-bottom: 20px;
}

/* Section Adjustment */
.section-3 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Column Styles for Section 3 */
.column-3,
.column-4,
.column-5 {
  flex: 1 1 30%;
  text-align: left;
}

/* Media Queries for Smaller Devices */
@media (max-width: 768px) {

  .section-1,
  .section-3 {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
  }

  .card {
    width: 90%;
    margin: 10px 0;
  }

  .elgin-resident-portal {
    padding: 10px;
  }
}
.accordion2 {
    margin-top: 0px;
    position: left; /* Allows placement relative to the container */
    vertical-align: top;
    width: 600px;
}

.accordion2-item {
    border-bottom: 1px solid #eee;
}

.accordion2-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center vertically */
    justify-content: left; /* Center horizontally */
    cursor: none;
    padding: 5px 0;
    padding-left: 10px;
    background-color: #A1B5CB;
}

.accordion2-header .icon {
    font-size: 20px;
    margin-right: 10px;
}

.accordion2-content {
    display: block; /* Ensures content is always visible */
    padding: 10px 0;
    color: black;
    font-size: 16px;
    padding-left: 15px;
}
.accordion {
    margin-top: 0px;
    position: left; /* Allows placement relative to the container */
    vertical-align: top;
    width: 100%;
    max-width: 600px;

  }
  
  .accordion-item {
    border-bottom: 1px solid #eee;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    cursor: pointer;
    padding: 10px 0;
  }
  
  .accordion-header .icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .accordion-header span {
    flex: 1;
    font-weight: bold;
    color: #333;
  }
  
  .accordion-header .toggle-icon {
    font-size: 18px;
    color: #153683;
  }
  
  .accordion-content {
    display: block; /* Ensures content is always visible */
    display: none;
    padding: 10px 0;
    color: black;
    font-size: 16px;
  }
</style>
